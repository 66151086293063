import { useQuery } from "@tanstack/react-query";
import { getTeamPlayers } from "../../services/apiTeamPlayers";
import { useState } from "react";

export function useTeamPlayers(teamId) {
  console.log(teamId);
  const [currentPage, setCurrentPage] = useState(1); // Add state for currentPage

  const {
    isLoading,
    data: TeamPlayers,
    error,
    refetch: refetchTeamPlayers,
  } = useQuery({
    queryKey: ["TeamPlayers", currentPage], // Include currentPage in the queryKey
    queryFn: async ({ queryKey }) => {
      const [, page] = queryKey; // Destructure the queryKey to get the page
      return await getTeamPlayers({ page, teamId }); // Pass page to getTeamPlayers
    },
  });

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  return {
    TeamPlayers,
    isLoading,
    error,
    refetchTeamPlayers,
    handlePageChange,
  };
}
