import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MatchDetails from "./MatchDetails";
import AllGroupMatches from "./AllGroupMatches";
import "./ChampionShipGroupMatches.css";
import { baseURL } from "../../api/API_Base_URL";
import Spinner from "../../ui/Spinner";
import Empty from "../../ui/Empty";
import useFetchData from "../../services/useFetchData";
import Modal from "../../ui/Modal";
import Button from "../../ui/Button";
import styled from "styled-components";
import EditGroupMatchForm from "./EditGroupMatchForm";
import EditPlayerSkills from "./EditPlayerSkills";
import EditGroupTeamRate from "./EditGroupTeamRate";
import Heading from "../../ui/Heading";
const ChampionShipGroupMatches = () => {
  const { groupId } = useParams();
  const { data, error, isLoading } = useFetchData(
    `${baseURL}/getGroupMatches/${groupId}`
  );

  const [selectedMatch, setSelectedMatch] = useState(null);
  const {
    data: matchDetail,
    isLoading: matchDetailLoader,
    error: matchDetailError,
  } = useFetchData(`${baseURL}/getGroupMatchDetails/${selectedMatch?.id}`);

  useEffect(() => {
    if (
      data &&
      data?.group &&
      data?.group?.matches &&
      data?.group?.matches?.length > 0
    ) {
      setSelectedMatch(data?.group?.matches[0]);
    }
  }, [data]);
  const handleMatchSelection = (match) => {
    setSelectedMatch(match);
  };
  if (isLoading) return <Spinner />;
  if (error) return <Empty />;
  console.log(selectedMatch);
  console.log(selectedMatch);
  if (data && data?.group && data?.group?.matches) console.log(selectedMatch);
  return (
    <>
      <Header>
        <div>
          <div>
            <img
              style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
              src={selectedMatch?.team1.imagePath}
            />
            <p>{selectedMatch?.team1.teamName}</p>
          </div>
        </div>
        <p>VS</p>
        <div>
          <div>
            <img
              style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
              src={selectedMatch?.team1.imagePath}
            />
            <p>{selectedMatch?.team2.teamName}</p>
          </div>
        </div>
      </Header>
      <Header2>
        <div>
          <p>Winner :</p>
          <p style={{ color: "black" }}>{selectedMatch?.winner}</p>
        </div>
        <div>
          <p>Location :</p>
          <p style={{ color: "black" }}>{selectedMatch?.location}</p>
        </div>
        <div>
          <p>stad :</p>
          <p style={{ color: "black" }}>{selectedMatch?.stad}</p>
        </div>
        <div>
          <p>time :</p>
          <p style={{ color: "black" }}>{selectedMatch?.time}</p>
        </div>
        <div>
          <p>date :</p>
          <p style={{ color: "black" }}>{selectedMatch?.date}</p>
        </div>
      </Header2>

      <div className="All-Matches">
        <AllGroupMatches
          onMatchSelect={handleMatchSelection}
          matches={data?.group?.matches}
          selectedMatchId={selectedMatch ? selectedMatch.id : null}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Heading h2="Edit Match">Edit Match</Heading>
          <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
            {selectedMatch && (
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>Edit Match Details</Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditGroupMatchForm matchId={selectedMatch?.id} />
                </Modal.Window>
              </Modal>
            )}
          </div>
        </div>
        <div>
          <Heading h2="Edit Team Players">Edit Team Players</Heading>
          <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
            {selectedMatch && (
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>
                    Edit {selectedMatch?.team1?.teamName} Players Skills
                  </Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditPlayerSkills
                    id={selectedMatch?.team1?.id}
                    teamId={selectedMatch?.team1?.id}
                  />
                </Modal.Window>
              </Modal>
            )}
          </div>
          <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
            {selectedMatch && (
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>
                    Edit {selectedMatch?.team2?.teamName} Players Skills
                  </Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditPlayerSkills
                    id={selectedMatch?.team2?.id}
                    teamId={selectedMatch?.team2?.id}
                  />
                </Modal.Window>
              </Modal>
            )}
          </div>
        </div>
        <div>
          {" "}
          <Heading h2="Edit Team Rate">Edit Team Rate</Heading>
          <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
            {selectedMatch && (
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>Edit {selectedMatch?.team1?.teamName} Rate</Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditGroupTeamRate
                    id={selectedMatch?.team1?.id}
                    teamName={selectedMatch?.team1?.TeamName}
                  />
                </Modal.Window>
              </Modal>
            )}
          </div>
          <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
            {selectedMatch && (
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>Edit {selectedMatch?.team2?.teamName} Rate</Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditGroupTeamRate
                    id={selectedMatch?.team2?.id}
                    teamName={selectedMatch?.team2?.TeamName}
                  />
                </Modal.Window>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChampionShipGroupMatches;
const Header = styled.header`
  background-color: var(--color-brand-500);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
`;
const Header2 = styled.header`
  background-color: var(--color-silver-100);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
  &.header3 {
    background-color: var(--color-silver-100);
  }
`;
