import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { usePrice } from "./usePrice";

import Table from "../../ui/Table";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import Spinner from "../../ui/Spinner";
import Row from "../../ui/Row";
import Empty from "../../ui/Empty";
import Heading from "../../ui/Heading";
import Pagination from "../../ui/Pagination";
import PriceRow from "./PriceRow";
import AddPrice from "./AddPrice";
const Price = () => {
  const { Price, isLoading, error, refetchPrice } = usePrice();
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Price?.data?.pagination?.total || 1;

  const handlePageChange = async (page) => {
    setCurrentPage(page); // Update the currentPage state
    await setCurrentPage(page); // Await the state update
    await refetchPrice({ page }); // Pass the updated page value to refetchCategory
  };

  console.log(Price?.data?.Price);
  if (isLoading) return <Spinner />;
  if (!Price?.prices?.length) return <Empty resourceName="Team Players" />;

  return (
    <>
      <Row type="horizontal">
        <Heading as="h1">Price Players</Heading>
        <AddPrice />
      </Row>
      <Menus>
        <Table columns="2fr 3fr 5fr  ">
          <Table.Header>
            <div>Price</div>
            <div>Code</div>
            <div></div>
          </Table.Header>

          <Table.Body
            data={Price?.prices}
            render={(Price) => <PriceRow key={Price.id} Price={Price} />}
          />

          <Table.Footer>
            <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              onPageChange={handlePageChange}
            />
          </Table.Footer>
        </Table>
      </Menus>{" "}
    </>
  );
};

export default Price;
