import Input from "../../ui/Input";
import Form from "../../ui/Form";
import Button from "../../ui/Button";
import FormRow from "../../ui/FormRow";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import { baseURL } from "../../api/API_Base_URL";
import styled from "styled-components";
import FileInput from "../../ui/FileInput";
import toast from "react-hot-toast";
import SpinnerMini from "../../ui/SpinnerMini";
import Spinner from "../../ui/Spinner";
import Empty from "../../ui/Empty";
import Select from "../../ui/Select";
import useFetchData from "../../services/useFetchData";
import usePost from "../../services/usePost";
function ChampionShipForm({ onCloseModal }) {
  const [formData, setFormData] = useState({
    team1_id: "",
    team2_id: "",
    date: "",
    time: "",
    location: "",
    stad: "",
  });
  const { postData, error: postError, isLoading: eventLoader } = usePost();
  const {
    data: team1,
    isLoading: team1Loader,
    error: team1Error,
  } = useFetchData(`${baseURL}/getAllTeams?per_page=50`);
  const {
    data: team2,
    isLoading: team2Loader,
    error: team2Error,
  } = useFetchData(`${baseURL}/getAllTeams?per_page=50`);

  const handleCreateEvent = (event) => {
    event.preventDefault();

    console.log("Event Data", formData);

    console.log(formData);
    postData(`${baseURL}/createH2HMatchDashboard`, formData, false);
    if (!eventLoader) toast.success("Head To Head Match Created Sucssesfuly");
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check the name attribute to update the corresponding team ID
    if (name === "team1_id") {
      setFormData({
        ...formData,
        team1_id: value,
      });
    } else if (name === "team2_id") {
      setFormData({
        ...formData,
        team2_id: value,
      });
    } else {
      // For other fields, update normally
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  console.log();
  console.log(team1, team2);
  console.log(team1?.data?.teams);
  if (team2Loader || team1Loader) {
    return <Spinner />;
  }
  if (team2Error || team1Error) {
    return <Empty error={"error with geting Teams Try Again "} />;
  }
  return (
    <>
      <Form
        onSubmit={handleCreateEvent}
        type={onCloseModal ? "modal" : "regular"}
      >
        <FormRow>
          {<Label htmlFor="team_id">Select Team1</Label>}
          <Select
            type="text"
            name="team1_id"
            id="team1_id"
            options={[
              // Default first option
              { value: "", label: "Select a team" },
              // Mapping over teams data
              ...(team1?.data?.teams.map((team) => ({
                value: team.id,
                label: team.teamName,
              })) || []), // Ensures that if team1?.data?.teams is null or undefined, an empty array is used
            ]}
            value={formData.team1_id}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          {<Label htmlFor="team_id">Select Team2</Label>}
          <Select
            name="team2_id"
            id="team2_id"
            type="number"
            value={formData.team2_id}
            required
            onChange={handleChange}
            options={[
              // Default first option
              { value: "", label: "Select a team" },
              // Mapping over teams data
              ...(team2?.data?.teams.map((team) => ({
                value: team.id,
                label: team.teamName,
              })) || []), // Ensures that if team2?.data?.teams is null or undefined, an empty array is used
            ]}
          />
        </FormRow>
        <FormRow>
          {<Label htmlFor="time">time</Label>}
          <Input
            type="time"
            name="time"
            id="time"
            min="1"
            max="120"
            onChange={handleChange}
            value={formData.time}
            required
          />
        </FormRow>
        <FormRow>
          {<Label htmlFor="date">Date:</Label>}
          <Input
            type="date"
            name="date"
            id="date"
            onChange={handleChange}
            value={formData.date}
            required
          />
        </FormRow>
        <FormRow label="entryPirce">
          {<Label htmlFor="stad">Stad :</Label>}
          <Input
            type="text"
            name="stad"
            id="stad"
            onChange={handleChange}
            value={formData.stad}
            required
          />
        </FormRow>
        <FormRow label="starDate">
          {<Label htmlFor="location">Location</Label>}
          <Input
            type="text"
            name="location"
            id="location"
            onChange={handleChange}
            value={formData.location}
            required
          />
        </FormRow>
        <FormRow>
          <Button
            variation="secondary"
            type="reset"
            onClick={() => onCloseModal?.()}
          >
            Cancel
          </Button>
          <Button>
            {/* Create new ChampionShip */}
            {eventLoader ? <SpinnerMini /> : "Create new Head To Head Match"}
          </Button>
        </FormRow>
      </Form>
    </>
  );
}

export default ChampionShipForm;

const Label = styled.label`
  font-weight: 500;
`;
