import toast from "react-hot-toast";
import { baseURL } from "../api/API_Base_URL";
import { PAGE_SIZE } from "../utils/constants";
export async function getCategory({ page }) {
  try {
    console.log(page);
    console.log(PAGE_SIZE);
    const response = await fetch(`${baseURL}/getAllUsers?per_page=2000`, {});
    console.log("hi");
    if (!response.ok) {
      throw new Error("category could not be loaded");
    }
    const data = await response.json(); // Await the JSON data
    console.log("hi");
    console.log(data); // Log the JSON data
    return data; // Return the JSON data
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getCategoryById(id) {
  try {
    console.log("by Id");

    const response = await fetch(`${baseURL}/category/show/${id}`);
    if (!response.ok) {
      throw new Error("Category information could not be fetched");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createCategoryData(newCategory, token) {
  console.log(token, "category Token");
  try {
    console.log("hi");

    let response;
    console.log("salehoje");
    console.log(newCategory);
    console.log(JSON.stringify(newCategory));
    response = await fetch(`${baseURL}/category`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify(newCategory),
    });

    if (!response.ok) {
      console.log("hi");

      throw new Error("Category could not be created");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function editCategoryData(newCategory, id, { token }) {
  try {
    console.log("hi");
    console.log(id);
    console.log(token);
    console.log(newCategory);
    let response;
    console.log("hi");
    response = await fetch(`${baseURL}/category/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newCategory),
    });

    if (!response.ok) {
      console.log("hi");

      throw new Error("Category could not be updated");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function deleteCategory(id) {
  console.log("delete0");
  console.log("2");
  console.log(id);
  try {
    const response = await fetch(`${baseURL}/deleteUser/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      toast();
      throw new Error("Category could not be deleted");
    }
    toast.success("Category successfully deleted");
    return await response.json();
  } catch (error) {
    toast.error(error.message);
    console.error(error);
    throw error;
  }
}

export async function searchCategory(filters) {
  try {
    const url = new URL(`${baseURL}/category`);
    Object.keys(filters).forEach((key) =>
      url.searchParams.append(key, filters[key])
    );

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Category could not be filtered");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}
