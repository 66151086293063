import Table from "../../ui/Table";
import Menus from "../../ui/Menus";
import Empty from "../../ui/Empty";
import { useHTH } from "./useHTH";
import Spinner from "../../ui/Spinner";
import Pagination from "../../ui/Pagination";
import { useState } from "react";
import { PAGE_SIZE } from "../../utils/constants";
import HTHMatchRow from "./HTHMatchRow";

function HTHTable() {
  const { HTH, isLoading, error, refetchHTH } = useHTH();

  console.log(HTH?.head2HeadMatches);
  if (isLoading) return <Spinner />;
  if (!HTH?.head2HeadMatches?.length)
    return <Empty resourceName="Head To Head Matches" />;

  return (
    <Menus>
      <Table columns="3fr 1fr 4fr 2fr 2fr 2fr  3.2rem">
        <Table.Header>
          <div>Team 1</div>
          <div></div>
          <div>Team 2</div>
          <div>Date </div>
          <div>Time </div>
          <div>Status </div>
          <div></div>
        </Table.Header>

        <Table.Body
          data={HTH?.head2HeadMatches}
          render={(HTH) => <HTHMatchRow key={HTH.id} HTH={HTH} />}
        />

        <Table.Footer>
          {/* <Pagination
            currentPage={currentPage}
            pageCount={pageCount}
            onPageChange={handlePageChange}
          /> */}
        </Table.Footer>
      </Table>
    </Menus>
  );
}

export default HTHTable;
