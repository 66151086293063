import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import Table from "../../ui/Table";
import { useParams } from "react-router-dom";
import useFetchData from "../../services/useFetchData";
import { baseURL } from "../../api/API_Base_URL";
import usePost from "../../services/usePost";
import Spinner from "../../ui/Spinner";
import Row from "../../ui/Row";
import HTHReqRow from "./HTHReqRow";
import Empty from "../../ui/Empty";

const HTHReq = () => {
  const [requests, setRequests] = useState([]);
  const { data, isLoading, error } = useFetchData(
    `${baseURL}/getALLH2HRequests`
  );
  console.log(data);
  const { postData } = usePost();
  useEffect(() => {
    if (data && data.head2HeadRequests) {
      setRequests(data.head2HeadRequests);
    }
  }, [data]);
  const handleApprove = async (requestId) => {
    console.log(requestId);
    await postData(`${baseURL}/acceptH2HRequest/${requestId}`);
    toast.success("Head To Head Match Approaved Successful");
    setRequests((current) => current.filter((req) => req.id !== requestId));
  };
  const handleReject = async (requestId) => {
    console.log(requestId);

    await postData(`${baseURL}/rejectH2HRequest/${requestId}`);
    setRequests((current) => current.filter((req) => req.id !== requestId));
    toast.success("Head To Head Match Reject Successful");
  };

  console.log(requests);
  if (isLoading) return <Spinner />;
  if (!data?.head2HeadRequests) return <Empty />;
  if (data && data.head2HeadRequests)
    return (
      <>
        <Table columns="1fr 1fr 1fr 1fr 1fr 1fr  1fr 1fr 1fr 1fr 1fr 1fr  ">
          <Table.Header>
            <div>Team 1</div>
            <div>Team 1 Coach</div>
            <div>Team 1 Coach Number </div>
            <div>Team 1 Iban</div>
            <div>Team 2</div>
            <div>Team 2 Coach</div>
            <div>Team 2 Coach Number </div>
            <div>Team 2 Iban</div>
            <div>Match Date</div>
            <div>Match Time</div>
            <div>Approve</div>
            <div>Reject</div>
          </Table.Header>

          <Table.Body
            data={data.head2HeadRequests}
            render={(Championship) => (
              <HTHReqRow
                handleReject={handleReject}
                handleApprove={handleApprove}
                key={Championship.id}
                teamsCount={data?.teamsCount}
                Championship={Championship}
              />
            )}
          />

          <Table.Footer>
            {/* <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              onPageChange={handlePageChange}
            /> */}
          </Table.Footer>
        </Table>
      </>
    );
};

export default HTHReq;
