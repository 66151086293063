import toast from "react-hot-toast";
import { baseURL } from "../api/API_Base_URL";
import { PAGE_SIZE } from "../utils/constants";

export async function getChampionship({ page }) {
  try {
    console.log(page);
    console.log(PAGE_SIZE);
    const response = await fetch(
      `${baseURL}/getAllChampionships?current_page=${page}&?per_page${PAGE_SIZE}`,
      {}
    );
    console.log("hi");
    if (!response.ok) {
      throw new Error("Championship could not be loaded");
    }
    const data = await response.json(); // Await the JSON data
    console.log("hi");
    console.log(data); // Log the JSON data
    return data; // Return the JSON data
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function deleteChampionship(id) {
  console.log("delete0");
  console.log("2");
  console.log(id);
  try {
    const response = await fetch(`${baseURL}/deleteChampionship/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      toast();
      throw new Error("Championship could not be deleted");
    }
    toast.success("Championship successfully deleted");
    return await response.json();
  } catch (error) {
    toast.error(error.message);
    console.error(error);
    throw error;
  }
}
export async function editChampionshipData(newChampionship, id) {
  try {
    console.log("hi");
    console.log(id);
    console.log(newChampionship);
    let response;
    console.log("hi");
    response = await fetch(`${baseURL}/editChampionship/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newChampionship),
    });

    if (!response.ok) {
      console.log("hi");

      throw new Error("Championship could not be updated");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}
