import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const storedAuth = localStorage.getItem("auth");
    return storedAuth ? JSON.parse(storedAuth) : {};
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateAuth = (newAuth) => {
    setAuth(newAuth);
    localStorage.setItem("auth", JSON.stringify(newAuth));
  };
  console.log(auth);
  const logoutAuth = () => {
    setAuth({});
    localStorage.removeItem("auth");
  };

  return (
    <AuthContext.Provider
      value={{ auth, isLoading, error, updateAuth, logoutAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
