import { Toaster } from "react-hot-toast";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { DarkModeProvider } from "./context/DarkModeContext";
import Dashboard from "./pages/Dashboard";
import GlobalStyles from "./style/GlobalStyles";
import Layout from "./ui/Layout";
import RequireAuth from "./ui/RequireAuth";
import Unauthorized from "./pages/Unauthorized";
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import AddUser from "./pages/AddUser";
import Consultation from "./pages/Consultation";
import ConsultationDetails from "./Feature/Consultation/ConsultationDetails";
import Category from "./pages/Category";
import SubCategory from "./pages/SubCategory";
import Developer from "./pages/Developer";
import Payment from "./pages/Payment";
import DeveloperDetails from "./Feature/Developer/DeveloperDetails";
import Property from "./pages/Property";
import PropertyDetails from "./Feature/Property/PropertyDetails";
import PaymentDetails from "./Feature/Payment/PaymentDetails";
import AddProperty from "./Feature/Property/AddProperty";
import PropertyRequest from "./pages/PropertyRequest";
import Bookings from "./pages/Bookings";
import Projects from "./pages/Projects";
import AddProjects from "./Feature/Projects/AddProjects";
import DashboardLayout from "./ui/DashboardLayout";
import Championship from "./pages/Championship";
import ChampionshipRequests from "./Feature/CreateChampionShip/ChampionshipRequests";
import ChampionshipGroup from "./pages/ChampionshipGroup";
import HTH from "./pages/HTH";
import HTHReq from "./Feature/HeadToHead/HTHReq";
import HTHMatchDetails from "./Feature/HeadToHead/HTHMatchDetails";
import { EditHTHMatch } from "./Feature/HeadToHead/EditHTHMatch";
import Teams from "./pages/Teams";
import TeamPlayers from "./pages/TeamPlayers";
import PriceTable from "./Feature/Price/PriceTable";
import ChampionShipGroupMatches from "./Feature/Group/ChampionShipGroupMatches";
import BattelDetails from "./Feature/EliminationStage/BattelDetails.jsx";
import EditRoundMatchesInfo from "./Feature/EliminationStage/EditRoundMatchesInfo.jsx";
// import EditTeamPoints from "./Feature/EliminationStage/EditTeamPoints.jsx";
// import EditTeamPlayersSkills from "./Feature/EliminationStage/EditTeamPlayersSkills.jsx";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 60 * 1000,
      staleTime: 0,
    },
  },
});
function App() {
  const AdminRole = { Admin: "Admin" };
  const AdminEmployeeRoles = { Admin: "Admin", Employee: "Employee" };
  return (
    <DarkModeProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* public routes */}

              <Route path="login" element={<Login />} />
              <Route path="unauthorized" element={<Unauthorized />} />
              <Route element={<RequireAuth allowedRoles={AdminRole} />}>
                <Route path="/" element={<DashboardLayout />} />
                <Route path="/add-user" element={<AddUser />} />
                <Route path="users" element={<Category />} />
                <Route path="teams" element={<Teams />} />
                <Route path="prices" element={<PriceTable />} />
                <Route path="team-players/:teamId" element={<TeamPlayers />} />

                <Route path="create-champion-ship" element={<Championship />} />
                <Route
                  path="all-championships/championship-requests/:championshipId"
                  element={<ChampionshipRequests />}
                />
                <Route
                  path="all-championships/championship-groups/:championshipId"
                  element={<ChampionshipGroup />}
                />
                <Route
                  path="group-matches/:groupId"
                  element={<ChampionShipGroupMatches />}
                />

                <Route
                  path="all-championships/championship-elimination/:championshipId"
                  element={<BattelDetails />}
                />
                <Route path="head-to-head-matches" element={<HTH />} />
                <Route path="head-to-head-requests" element={<HTHReq />} />
                <Route
                  path="head-to-head-match-details/:headToHeadMatchId"
                  element={<HTHMatchDetails />}
                />
                <Route
                  path="edit-head-to-head-match-details/:headToHeadMatchId"
                  element={<EditHTHMatch />}
                />
                <Route
                  path="edit-rounds-matches/:matchId"
                  element={<EditRoundMatchesInfo />}
                />
                {/* <Route
                  path="add-team-points/:teamId"
                  element={<EditTeamPoints />}
                />
                <Route
                  path="add-team-players-skills/:teamId"
                  element={<EditTeamPlayersSkills />}
                /> */}
              </Route>

              {/*  Admin protected routes  */}
              {/* <Route element={<RequireAuth allowedRoles={AdminRole} />}>
                <Route path="/add-user" element={<AddUser />} />
                <Route path="category" element={<Category />} />
                <Route
                  path="category/:categoryId"
                  element={<ConsultationDetails />}
                />
                <Route path="subcategory" element={<SubCategory />} />

                <Route
                  path="subcategory/:subcategoryId"
                  element={<ConsultationDetails />}
                />
                <Route path="payment" element={<Payment />} />
                <Route path="payment/:paymentId" element={<PaymentDetails />} />
                <Route path="developer" element={<Developer />} />
                <Route
                  path="developer/:developerId"
                  element={<DeveloperDetails />}
                />
              </Route>

              <Route
                element={<RequireAuth allowedRoles={AdminEmployeeRoles} />}
              >
                <Route path="/" element={<Dashboard />} />
                <Route path="consultations" element={<Consultation />} />
                <Route
                  path="consultation/:consultationId"
                  element={<ConsultationDetails />}
                />
                <Route path="property-requests" element={<PropertyRequest />} />
                <Route path="booking" element={<Bookings />} />
                <Route path="governorate" element={<Governorate />} />

                <Route path="property" element={<Property />} />

                <Route path="add-projects" element={<AddProjects />} />

                <Route
                  path="property/:propertyId"
                  element={<PropertyDetails />}
                />
              </Route> */}

              {/* catch all */}
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Toaster
          position="top-center"
          gutter={12}
          containerStyle={{ margin: "8px" }}
          toastOptions={{
            success: {
              duration: 3000,
            },
            error: {
              duration: 5000,
            },
            style: {
              fontSize: "16px",
              maxWidth: "500px",
              padding: "16px 24px",
              backgroundColor: "var(--color-grey-0)",
              color: "var(--color-grey-700)",
            },
          }}
        />
      </QueryClientProvider>
    </DarkModeProvider>
  );
}

export default App;
