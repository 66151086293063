import toast from "react-hot-toast";
import { baseURL } from "../api/API_Base_URL";
import { PAGE_SIZE } from "../utils/constants";

export async function getPrice({ page }) {
  try {
    console.log(page);
    console.log(PAGE_SIZE);
    const response = await fetch(
      `${baseURL}/getALLPrices?current_page=${page}&?per_page${PAGE_SIZE}`,
      {}
    );
    console.log("hi");
    if (!response.ok) {
      throw new Error("Price could not be loaded");
    }
    const data = await response.json(); // Await the JSON data
    console.log("hi");
    console.log(data); // Log the JSON data
    return data; // Return the JSON data
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function deletePrice(id) {
  console.log("delete0");
  console.log("2");
  console.log(id);
  try {
    const response = await fetch(`${baseURL}/deletePrice/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      toast();
      throw new Error("Price could not be deleted");
    }
    toast.success("Price successfully deleted");
    return await response.json();
  } catch (error) {
    toast.error(error.message);
    console.error(error);
    throw error;
  }
}
export async function editPriceData(newPrice, id) {
  try {
    console.log("hi");
    console.log(id);
    console.log(newPrice);
    let response;
    console.log("hi");
    response = await fetch(`${baseURL}/editPrice/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPrice),
    });

    if (!response.ok) {
      console.log("hi");

      throw new Error("Price could not be updated");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function createPriceData(newPrice) {
  try {
    console.log("hi");

    let response;
    console.log("salehoje");
    console.log(newPrice);
    console.log(JSON.stringify(newPrice));
    response = await fetch(`${baseURL}/addPrice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify(newPrice),
    });

    if (!response.ok) {
      console.log("hi");

      throw new Error("Price could not be created");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}
