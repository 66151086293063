import { useQuery } from "@tanstack/react-query";
import { getChampionship } from "../../services/apiChampionship";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";

export function useChampionship() {
  const { auth } = useAuth();
  const [currentPage, setCurrentPage] = useState(1); // Add state for currentPage

  const {
    isLoading,
    data: Championship,
    error,
    refetch: refetchChampionship,
  } = useQuery({
    queryKey: ["Championship", currentPage], // Include currentPage in the queryKey
    queryFn: async ({ queryKey }) => {
      const [, page] = queryKey; // Destructure the queryKey to get the page
      return await getChampionship({ page }); // Pass page to getChampionship
    },
  });

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  return {
    Championship,
    isLoading,
    error,
    refetchChampionship,
    handlePageChange,
  };
}
