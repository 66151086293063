// Search.js
import React, { useState } from "react";
import styled, { css } from "styled-components";
import Button from "./Button";
import Input from "./Input";

const SearchInput = styled.input`
  border: 1px solid var(--color-grey-100);
  background-color: var(--color-grey-0);
  box-shadow: var(--shadow-sm);
  border-radius: var(--border-radius-sm);
  padding: 0.4rem;
  display: flex;
  gap: 0.4rem;
`;
const SearchForm = styled.form`
  display: flex;
  gap: 1rem;
`;
function Search({ handleSearch }) {
  const [searchQuery, setSearchQuery] = useState("");

  function handleChange(e) {
    setSearchQuery(e.target.value);
    handleSearch(searchQuery);
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleSearch(searchQuery);
  }

  return (
    // <SearchForm onSubmit={handleSubmit}>
    <Input
      type="text"
      value={searchQuery}
      onChange={handleChange}
      placeholder="Search..."
    />
    // </SearchForm>
  );
}

export default Search;
