import { useEffect, useRef, useState } from "react";
import Button from "../../ui/Button";
import Form from "../../ui/Form";
import Input from "../../ui/Input";
import FormRowVertical from "../../ui/FormRowVertical";
import SpinnerMini from "../../ui/SpinnerMini";
import { login } from "../../services/apiAuth";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function LoginForm() {
  const { auth, updateAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const userRef = useRef();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  //   useEffect(() => {
  //     userRef.current.focus();
  //   }, []);

  useEffect(() => {
    if (auth.token) {
      navigate("/");
      // toast.success("You are already logged in.");
    }
  }, [auth, navigate]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userName || !password) return;

    setIsLoading(true);
    setError(null);

    try {
      const { user, token, role } = await login(
        userName,
        password,
        setIsLoading,
        setError
      );
      updateAuth({ user, role, token });
      setUserName("");
      setPassword("");
      navigate(from, { replace: true });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormRowVertical label="Username">
        <Input
          type="text"
          id="Username"
          // This makes this form better for password managers
          autoComplete="username"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          disabled={isLoading}
        />
      </FormRowVertical>

      <FormRowVertical label="Password">
        <Input
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isLoading}
        />
      </FormRowVertical>
      <FormRowVertical>
        <Button size="large" disabled={isLoading}>
          {!isLoading ? "Log in" : <SpinnerMini />}
        </Button>
      </FormRowVertical>
    </Form>
  );
}

export default LoginForm;
