import { baseURL } from "../api/API_Base_URL";
import { toast } from "react-hot-toast";

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong");
  }
  return response.json();
};

export const login = async (userName, password, setIsLoading, setError) => {
  try {
    console.log(userName, password);

    setIsLoading(true);
    const response = await fetch(`${baseURL}/admin/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userName, password }),
    });

    const data = await handleResponse(response);
    const user = data?.admin?.userName;
    const token = data?.token;
    const role = data?.data?.role;
    console.log(data.token);
    toast.success("Login successful");
    return { user, token };
  } catch (error) {
    setError(error.message || "Login failed");
    throw new Error("Login failed");
  } finally {
    setIsLoading(false);
  }
};

export const logout = async ({ token }, setIsLoading) => {
  try {
    setIsLoading(true);
    const response = await fetch(`${baseURL}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    await handleResponse(response);
    toast.success("Logout successful");
  } catch (error) {
    // setError(error.message || "Logout failed");
    throw new Error("Logout failed");
  } finally {
    setIsLoading(false);
  }
};
export const userSignup = async (
  { token },
  userName,
  password,

  setIsLoading
) => {
  try {
    console.log("to");
    setIsLoading(true);
    const response = await fetch(`${baseURL}/admin/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userName: userName,
        password: password,
      }),
    });

    await handleResponse(response);
    toast.success("Create Admin success");
  } catch (error) {
    toast.error("Add Admin failed");

    throw new Error("Signup failed");
  } finally {
    setIsLoading(false);
  }
};
