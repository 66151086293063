import { baseURL } from "../api/API_Base_URL";
import { PAGE_SIZE } from "../utils/constants";
import toast from "react-hot-toast";

export async function getTeamPlayers({ page, teamId }) {
  try {
    console.log(teamId, "id");
    const response = await fetch(
      `${baseURL}/getTeamPlayers/${teamId}?current_page=${page}&?per_page${PAGE_SIZE}`,
      {}
    );
    console.log("hi");
    if (!response.ok) {
      throw new Error("Team could not be loaded");
    }
    const data = await response.json(); // Await the JSON data
    console.log("hi");
    console.log(data); // Log the JSON data
    return data; // Return the JSON data
  } catch (error) {
    console.error(error);
    throw error;
  }
}
// export async function deleteChampionship(id) {
//     console.log("delete0");
//     console.log("2");
//     console.log(id);
//     try {
//       const response = await fetch(`${baseURL}/deleteTeamPlayer/${id}`, {
//         method: "DELETE",
//       });

//       if (!response.ok) {
//         toast();
//         throw new Error("Team Player could not be deleted");
//       }
//       toast.success("Team Player successfully deleted");
//       return await response.json();
//     } catch (error) {
//       toast.error(error.message);
//       console.error(error);
//       throw error;
//     }
//   }
