import Table from "../../ui/Table";
import Menus from "../../ui/Menus";
import Empty from "../../ui/Empty";

import { useChampionship } from "./useChampionship";
import Spinner from "../../ui/Spinner";
import Pagination from "../../ui/Pagination";
import ChampionshipRow from "./ChampionshipRow";
import { useState } from "react";
import { PAGE_SIZE } from "../../utils/constants";

function ChampionshipTable() {
  const { Championship, isLoading, error, refetchChampionship } =
    useChampionship();
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Championship?.data?.pagination?.total || 1;

  const handlePageChange = async (page) => {
    setCurrentPage(page); // Update the currentPage state
    await setCurrentPage(page); // Await the state update
    await refetchChampionship({ page }); // Pass the updated page value to refetchChampionship
  };

  console.log(Championship?.data?.championships);
  if (isLoading) return <Spinner />;
  if (!Championship?.data?.championships?.length)
    return <Empty resourceName="consultation" />;

  return (
    <Menus>
      <Table columns="2fr  1fr 2fr 2fr 2fr  3.2rem">
        <Table.Header>
          <div>Championship Name</div>
          <div>Status</div>
          <div>طلبات الانضمام</div>
          <div>دوري المجموعات</div>
          <div>دوري الاقصاء</div>
          <div></div>
        </Table.Header>

        <Table.Body
          data={Championship?.data?.championships}
          render={(Championship) => (
            <ChampionshipRow
              key={Championship.id}
              Championship={Championship}
            />
          )}
        />

        <Table.Footer>
          <Pagination
            currentPage={currentPage}
            pageCount={pageCount}
            onPageChange={handlePageChange}
          />
        </Table.Footer>
      </Table>
    </Menus>
  );
}

export default ChampionshipTable;
