import React, { useState } from "react";
import usePut from "../../services/usePut";
import { baseURL } from "../../api/API_Base_URL";
import useFetchData from "../../services/useFetchData";
import Form from "../../ui/Form";
import FormRow from "../../ui/FormRow";
import Input from "../../ui/Input";
import Button from "../../ui/Button";
import Spinner from "../../ui/Spinner";
import Empty from "../../ui/Empty";

const EditPlayerSkills = ({ id, teamId }) => {
  console.log(id, "id");
  const { putData } = usePut(); // Rename postData to putData

  const [formData4, setFormData4] = useState({ skills: "" });
  const handleInputChange = (playerId, value) => {
    console.log(playerId, value);
    setFormData4((prevData) => ({
      ...prevData,
      skills: value,
    }));
    console.log(formData4);
  };
  const {
    data: team1Players,
    isLoading: team1PLayersLoader,
    error: team1PlayersError,
  } = useFetchData(`${baseURL}/getTeamPlayers/${id}`);
  const handleUpatePlayerSkills = (playerId, e) => {
    e.preventDefault();

    const skillsInt = parseInt(formData4.skills);
    putData(
      `${baseURL}/editUserSkills/${playerId}`,
      { skills: skillsInt },
      false
    );
  };
  if (team1PLayersLoader) return <Spinner />;
  // if(!team1Players.players.length)
  if (team1PlayersError) return <Empty />;
  return (
    <>
      {team1Players?.players?.map((player) => (
        <Form
          onSubmit={(e) => handleUpatePlayerSkills(player.id, e)}
          key={player.id}
        >
          <FormRow>
            <Input
              type="number"
              step="0.1"
              value={formData4[player.id]?.skills}
              onChange={(e) => handleInputChange(player.id, e.target.value)}
              placeholder={player.fullName}
            />
            <Button type="submit">Add Skill</Button>
          </FormRow>
        </Form>
      ))}
      ;
    </>
  );
};

export default EditPlayerSkills;
// selectedMatch.team1.id
