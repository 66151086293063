import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../services/useFetchData";
import { baseURL } from "../../api/API_Base_URL";
import Spinner from "../../ui/Spinner";
import Empty from "../../ui/Empty";
import usePost from "../../services/usePost";
import styled from "styled-components";
import FileInput from "../../ui/FileInput";
import {
  HiBellAlert,
  HiMiniBackspace,
  HiOutlineHomeModern,
} from "react-icons/hi2";
import toast from "react-hot-toast";

import Form from "../../ui/Form";
import FormRow from "../../ui/FormRow";
import Button from "../../ui/Button";
import Input from "../../ui/Input";
import usePut from "../../services/usePut";
import SpinnerMini from "../../ui/SpinnerMini";
const EditEliminationMatch = ({ matchId }) => {
  const { groupId } = useParams();
  const { data, isLoading, error } = useFetchData(
    `${baseURL}/getRoundMatchDetails/${matchId}`
  );
  const { postData, error: postError, isLoading: eventLoader } = usePost();
  const {
    putData,
    error: putError,
    isLoading: eventPutLoader,
    response: putResponse,
  } = usePut();
  const [formData, setFormData] = useState({
    time: "",
    winner: "",
    stad: "",
    date: "",
    location: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    console.log(`${baseURL}/editRoundMatches/${matchId}`);
    putData(`${baseURL}/editRoundMatches/${matchId}`, formData, false);
    if (putResponse && putResponse.ok) {
      toast.success("Match Updated Successfully!");
    } else {
      toast.error("Match Not Edited");
    }
  };
  console.log(data);
  if (isLoading) return <Spinner />;
  if (!data) return <Empty />;
  console.log(data);
  return (
    <StyledBookingDataBox>
      <Header>
        <div>
          <div>
            <img
              style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
              src={data?.match?.team1?.imagePath}
            />
            <p>{data?.match?.team1?.teamName}</p>
          </div>
        </div>
        <p>VS</p>
        <div>
          <div>
            <img
              style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
              src={data?.match?.team1?.imagePath}
            />
            <p>{data?.match?.team2?.teamName}</p>
          </div>
        </div>
      </Header>
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <label> Location:</label>
          <Input
            type="text"
            value={formData.location}
            onChange={(e) =>
              setFormData({ ...formData, location: e.target.value })
            }
          />
        </FormRow>
        <FormRow>
          <label> Stad:</label>
          <Input
            type="text"
            value={formData.stad}
            onChange={(e) => setFormData({ ...formData, stad: e.target.value })}
          />
        </FormRow>{" "}
        <FormRow>
          <label> Time:</label>
          <Input
            type="time"
            style={{ width: "20rem" }}
            value={formData.time}
            onChange={(e) => setFormData({ ...formData, time: e.target.value })}
          />
        </FormRow>
        <FormRow>
          <label> Date:</label>
          <Input
            style={{ width: "20rem" }}
            type="date"
            value={formData.date}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          />
        </FormRow>{" "}
        <FormRow>
          <label> Date:</label>
          <StyledSelect
            name="team_id"
            id="team_id"
            onChange={(e) => {
              setFormData({ ...formData, winner: e.target.value });
            }}
            value={formData.winner}
          >
            <option value="">Select Winner</option>
            <option value={data?.match?.team1?.id}>
              {data?.match?.team1?.teamName}
            </option>
            <option value={data.match?.team2.id}>
              {data?.match?.team2?.teamName}
            </option>
          </StyledSelect>
        </FormRow>
        <FormRow>
          <Button type="submit">
            {eventPutLoader ? <SpinnerMini /> : "Edit"}
          </Button>{" "}
        </FormRow>
      </Form>
    </StyledBookingDataBox>
  );
};
export default EditEliminationMatch;
const Header = styled.header`
  background-color: var(--color-brand-500);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
`;
const StyledBookingDataBox = styled.section`
  /* Box */
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);

  overflow: hidden;
`;
const Header2 = styled.header`
  background-color: var(--color-silver-100);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
  &.header3 {
    background-color: var(--color-silver-100);
  }
`;
const StyledSelect = styled.select`
  font-size: 1.4rem;
  padding: 0.8rem 1.2rem;
  border: 1px solid
    ${(props) =>
      props.type === "white"
        ? "var(--color-grey-100)"
        : "var(--color-grey-300)"};
  border-radius: var(--border-radius-sm);
  background-color: var(--color-grey-0);
  font-weight: 500;
  box-shadow: var(--shadow-sm);
`;
