import React from "react";
import "./EditRoundMatchesInfo.css";
import { Link, useParams } from "react-router-dom";
// import EditMatchInfoForm from "./EditGroupMatchInfoForm";
import Spinner from "../../ui/Spinner";
import Empty from "../../ui/Empty";
import useFetchData from "../../services/useFetchData";
import { baseURL } from "../../api/API_Base_URL";
import Modal from "../../ui/Modal";
import Button from "../../ui/Button";
import styled from "styled-components";

import EditGroupMatchForm from "../Group/EditGroupMatchForm";
import usePut from "../../services/usePut";
import Heading from "../../ui/Heading";
import EditGroupTeamRate from "../Group/EditGroupTeamRate";
import EditPlayerSkills from "../Group/EditPlayerSkills";
import EditEliminationMatch from "./EditEliminationMatch";
const EditRoundMatchesInfo = () => {
  const { matchId } = useParams();
  console.log(matchId);
  const { data, isLoading, error } = useFetchData(
    `${baseURL}/getRoundMatchDetails/${matchId}`
  );
  const { putData } = usePut();
  const handleEditGroupMatch = (formData) => {
    putData(`${baseURL}/editRoundMatches/${matchId}`, formData, false) // Use putData here
      .then(() => {
        console.log("تم تعديل المعلومات بنجاح");
      })
      .catch((error) => {
        console.error("Error updating match info:", error);
      });
    console.log(formData);
  };
  if (isLoading) return <Spinner />;
  if (error) return <Empty />;
  if (data && data.match) {
    const teamsExist =
      data.match.team1 && data.match.team2 && data.match.winner == null;
    console.log(teamsExist, "saleh");

    const teams = [
      {
        team: data.match.team1
          ? data.match.team1
          : { id: "0", teamName: "no team" },
      },
      {
        team: data.match.team2
          ? data.match.team2
          : { id: "0", teamName: "no team" },
      },
    ];

    console.log(teams);
    return (
      <>
        <h1 className="round-match-h1">تعديل معلومات المباراة</h1>
        {/* <EditMatchInfoForm
          onSubmit={handleEditGroupMatch}
          teams={teams}
          canSubmit={teamsExist}
        /> */}

        <Header>
          <div>
            <div>
              <img
                style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
                src={data?.match?.team1.imagePath}
              />
              <p>{data?.match?.team1.teamName}</p>
            </div>
          </div>
          <p>VS</p>
          <div>
            <div>
              <img
                style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
                src={data?.match?.team1.imagePath}
              />
              <p>{data?.match?.team2.teamName}</p>
            </div>
          </div>
        </Header>
        <Header2>
          <div>
            <p>Winner :</p>
            <p style={{ color: "black" }}>{data?.match?.winner}</p>
          </div>
          <div>
            <p>Location :</p>
            <p style={{ color: "black" }}>{data?.match?.location}</p>
          </div>
          <div>
            <p>stad :</p>
            <p style={{ color: "black" }}>{data?.match?.stad}</p>
          </div>
          <div>
            <p>time :</p>
            <p style={{ color: "black" }}>{data?.match?.time}</p>
          </div>
          <div>
            <p>date :</p>
            <p style={{ color: "black" }}>{data?.match?.date}</p>
          </div>
        </Header2>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Heading h2="Edit Match">Edit Match</Heading>
            <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>Edit Match Details</Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditEliminationMatch matchId={data?.match?.id} />
                </Modal.Window>
              </Modal>
            </div>
          </div>
          <div>
            <Heading h2="Edit Team Players">Edit Team Players</Heading>
            <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>
                    Edit {data?.match?.team1?.teamName} Players Skills
                  </Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditPlayerSkills
                    id={data?.match?.team1?.id}
                    teamId={data?.match?.team1?.id}
                  />
                </Modal.Window>
              </Modal>
            </div>
            <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>
                    Edit {data?.match?.team2?.teamName} Players Skills
                  </Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditPlayerSkills
                    id={data?.match?.team2?.id}
                    teamId={data?.match?.team2?.id}
                  />
                </Modal.Window>
              </Modal>
            </div>
          </div>
          <div>
            {" "}
            <Heading h2="Edit Team Rate">Edit Team Rate</Heading>
            <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>Edit {data?.match?.team1?.teamName} Rate</Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditGroupTeamRate
                    id={data?.match?.team1?.id}
                    teamName={data?.match?.team1?.TeamName}
                  />
                </Modal.Window>
              </Modal>
            </div>
            <div className="Edit-Match-Details" style={{ margin: "3rem auto" }}>
              <Modal>
                <Modal.Open opens="cabin-form">
                  <Button>Edit {data?.match?.team2?.teamName} Rate</Button>
                </Modal.Open>
                <Modal.Window name="cabin-form">
                  <EditGroupTeamRate
                    id={data?.match?.team2?.id}
                    teamName={data?.match?.team2?.TeamName}
                  />
                </Modal.Window>
              </Modal>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default EditRoundMatchesInfo;
const Header = styled.header`
  background-color: var(--color-brand-500);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
`;
const Header2 = styled.header`
  background-color: var(--color-silver-100);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
  &.header3 {
    background-color: var(--color-silver-100);
  }
`;
