import { useForm } from "react-hook-form";
import Input from "../../ui/Input";
import Form from "../../ui/Form";
import Button from "../../ui/Button";
import FormRow from "../../ui/FormRow";
import { useCreatePrice } from "./useCreatePrice";
import useAuth from "../../hooks/useAuth";
function PriceForm({ cabinToEdit = {}, onCloseModal }) {
  const { auth } = useAuth();
  const { createPrice } = useCreatePrice(auth.token);

  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: "",
  });
  const { errors } = formState;

  function onSubmit(data) {
    const PriceData = {
      code: data.code,
      price: data.price,
    };
    console.log(data, PriceData);

    createPrice(PriceData, {
      onSuccess: () => {
        reset();
        onCloseModal?.();
      },
    });
  }

  function onError(errors) {
    console.log(errors);
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit, onError)}
      type={onCloseModal ? "modal" : "regular"}
    >
      <FormRow label="code" error={errors?.fr?.message}>
        <Input
          type="text"
          id="code"
          {...register("code", {
            required: "This field is required",
          })}
        />
      </FormRow>
      <FormRow label="price" error={errors?.fr?.message}>
        <Input
          type="number"
          id="price"
          {...register("price", {
            required: "This field is required",
          })}
        />
      </FormRow>
      <FormRow>
        <Button
          variation="secondary"
          type="reset"
          onClick={() => onCloseModal?.()}
        >
          Cancel
        </Button>
        <Button>Edit Price</Button>
      </FormRow>
    </Form>
  );
}

export default PriceForm;
