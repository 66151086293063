import Heading from "../ui/Heading";
import Row from "../ui/Row";
import CategoryTable from "../Feature/Category/CategoryTable";
import AddCategory from "../Feature/Category/AddCategory";

function Category() {
  return (
    <>
      <Row type="horizontal">
        <Heading as="h1">All Users</Heading>
        {/* <AddCategory /> */}
      </Row>
      <CategoryTable />
    </>
  );
}
export default Category;
