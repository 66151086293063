import styled from "styled-components";
import { format, isToday } from "date-fns";
import {
  HiArrowDownOnSquare,
  HiArrowUpOnSquare,
  HiEye,
  HiPencil,
  HiTrash,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Tag from "../../ui/Tag";
import Table from "../../ui/Table";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import ConfirmDelete from "../../ui/ConfirmDelete";
// import { useDeletePropertyRequest } from "./useDeletePropertyRequest";
import useAuth from "../../hooks/useAuth";
import { deletePropertyRequest } from "../../services/apiPropertyRequest";
import PropertyRequestForm from "./PropertyRequestForm";
import EditPropertyRequestForm from "./EditPropertyRequestForm";
const Cabin = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-600);
  font-family: "Sono";
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;

const Amount = styled.div`
  font-family: "Sono";
  font-weight: 500;
`;

function PropertyRequestRow({ propertyRequest }) {
  //   const { id, name, phone, email, status } = propertyRequest;
  const navigate = useNavigate();
  const { auth } = useAuth();
  // const { deleteConsultation, isDeleting } = useDeleteConsultation();
  console.log();
  const statusToTagName = {
    unconfirmed: "silver",
    pending: "blue",
    confirmed: "green",
  };
  return (
    <Table.Row>
      {/* <Cabin>{name}</Cabin> */}

      <Stacked>
        {/* <span>{name}</span> */}
        {/* <span>{email}</span> */}
      </Stacked>
      <Stacked>{/* <span>{phone}</span> */}</Stacked>

      {/* <Tag type={statusToTagName[status]}>{status.replace("-", " ")}</Tag> */}

      <Modal>
        <Menus.Menu>
          <Menus.Toggle id={1} />
          <Menus.List id={1}>
            <Menus.Button
              icon={<HiEye />}
              onClick={() => navigate(`/property-request/${1}`)}
            >
              See details
            </Menus.Button>

            {/* {status === "unconfirmed" && (
              <Menus.Button
                icon={<HiArrowDownOnSquare />}
                onClick={() => navigate(`/checkin/${id}`)}
              >
                Check in
              </Menus.Button>
            )} */}
            <Modal.Open opens="edit">
              <Menus.Button icon={<HiPencil />}>Edit</Menus.Button>
            </Modal.Open>

            <Modal.Open opens="delete">
              <Menus.Button icon={<HiTrash />}>Delete booking</Menus.Button>
            </Modal.Open>
          </Menus.List>
          <Modal.Window name="edit">
            <EditPropertyRequestForm id={1} />
          </Modal.Window>
        </Menus.Menu>

        <Modal.Window name="delete">
          <ConfirmDelete
            resourceName="PropertyRequest"
            // disabled={isDeleting}
            onConfirm={() => deletePropertyRequest({ token: auth.token }, 1)}
          />
        </Modal.Window>
      </Modal>
    </Table.Row>
  );
}

export default PropertyRequestRow;
