// useFetchData.js
import { useState, useEffect } from "react";
const useFetchData = (url) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  // console.log(url);
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        setError("");
        const res = await fetch(url);
        if (!res.ok) {
          throw new Error("Something went wrong with fetching data");
        }
        const fetchedData = await res.json();
        setData(fetchedData);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [url]);
  // console.log(data, isLoading, error);
  return { data, isLoading, error };
};

export default useFetchData;
