import toast from "react-hot-toast";
import { baseURL } from "../api/API_Base_URL";
import { PAGE_SIZE } from "../utils/constants";

export async function getTeam({ page }) {
  try {
    console.log(page);
    console.log(PAGE_SIZE);
    const response = await fetch(`${baseURL}/getAllTeams?per_page=2000`, {});
    console.log("hi");
    if (!response.ok) {
      throw new Error("Team could not be loaded");
    }
    const data = await response.json(); // Await the JSON data
    console.log("hi");
    console.log(data); // Log the JSON data
    return data; // Return the JSON data
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function deleteTeam(id) {
  console.log("delete0");
  console.log("2");
  console.log(id);
  try {
    const response = await fetch(`${baseURL}/deleteTeam/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      toast();
      throw new Error("Team could not be deleted");
    }
    toast.success("Team successfully deleted");
    return await response.json();
  } catch (error) {
    toast.error(error.message);
    console.error(error);
    throw error;
  }
}
