import Input from "../../ui/Input";
import Form from "../../ui/Form";
import Button from "../../ui/Button";
import FormRow from "../../ui/FormRow";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import { baseURL } from "../../api/API_Base_URL";
import styled from "styled-components";
import FileInput from "../../ui/FileInput";
import toast from "react-hot-toast";
import Heading from "../../ui/Heading";
function EditDeveloperForm({ id }) {
  const { auth } = useAuth();

  const [images, setImages] = useState([]);
  const [enName, setEnName] = useState("");
  const [frName, setfrName] = useState("");
  const [website, setWebsite] = useState("");
  const handleEnNameChange = (e) => {
    setEnName(e.target.value);
  };

  const handleArNameChange = (e) => {
    setfrName(e.target.value);
  };
  const handleWebsite = (e) => {
    setWebsite(e.target.value);
  };
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log({
      images: images,
      en: { name: enName },
      fr: { name: frName },
    });

    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("en[name]", enName);
      formData.append("fr[name]", frName);
      formData.append("website", website);
      images.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });

      console.log(formData);

      const response = await fetch(`${baseURL}/developer`, {
        method: "POST",
        headers: {
          // Don't set Content-Type header, it will be automatically set by FormData
          Authorization: `Bearer ${auth.token}`,
        },
        body: formData,
      });
      const data = await response.json();
      toast.success("developer created successfully");
      console.log(data);
    } catch (error) {
      toast.error("developer created successfully");

      console.error("Error", error);
    }
  };
  return (
    <>
      {/* <Heading h1="Edit Developer">Edit Developer</Heading> */}
      <Form onSubmit={handleSubmit}>
        <FormRow label="English Name">
          {<Label>English Name</Label>}
          <Input type="text" value={enName} onChange={handleEnNameChange} />
        </FormRow>
        <FormRow label="الاسم بالعربية">
          {<Label>Arabic Name</Label>}
          <Input type="text" value={frName} onChange={handleArNameChange} />
        </FormRow>
        <FormRow label="Website">
          {<Label>Website</Label>}
          <Input type="text" value={website} onChange={handleWebsite} />
        </FormRow>
        <FileInput type="file" onChange={handleImageChange} multiple />
        <FormRow>
          <Button variation="secondary" type="reset">
            Cancel
          </Button>
          <Button>Create new Property </Button>
        </FormRow>
      </Form>
    </>
  );
}

export default EditDeveloperForm;

const Label = styled.label`
  font-weight: 500;
`;
