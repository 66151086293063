import { useQuery } from "@tanstack/react-query";
import { getPrice } from "../../services/apiPrice";
import { useState } from "react";

export function usePrice() {
  const [currentPage, setCurrentPage] = useState(1); // Add state for currentPage

  const {
    isLoading,
    data: Price,
    error,
    refetch: refetchPrice,
  } = useQuery({
    queryKey: ["Price", currentPage], // Include currentPage in the queryKey
    queryFn: async ({ queryKey }) => {
      const [, page] = queryKey; // Destructure the queryKey to get the page
      return await getPrice({ page }); // Pass page to getPrice
    },
  });

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  return {
    Price,
    isLoading,
    error,
    refetchPrice,
    handlePageChange,
  };
}
