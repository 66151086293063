import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { createPriceData } from "../../services/apiPrice";

export function useCreatePrice() {
  const queryClient = useQueryClient();
  const { mutate: createPrice, isLoading: isCreating } = useMutation({
    mutationFn: (newPrice) => createPriceData(newPrice),
    onSuccess: () => {
      toast.success("New Price successfully created");
      queryClient.invalidateQueries({ queryKey: ["Price"] });
    },
    onError: (err) => toast.error(err.message),
  });

  return { isCreating, createPrice };
}
