import Table from "../../ui/Table";
import Menus from "../../ui/Menus";
import Empty from "../../ui/Empty";

import { useCategory } from "./useCategory";
import Spinner from "../../ui/Spinner";
import Pagination from "../../ui/Pagination";
import CategoryRow from "./CategoryRow";
import { useState } from "react";
import { PAGE_SIZE } from "../../utils/constants";

import CategoryTableOperations from "./CategoryTableOperation";
function CategoryTable() {
  const { category, isLoading, error, refetchCategory } = useCategory();
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = category?.data?.pagination?.total || 1;

  const handlePageChange = async (page) => {
    setCurrentPage(page); // Update the currentPage state
    await setCurrentPage(page); // Await the state update
    await refetchCategory({ page }); // Pass the updated page value to refetchCategory
  };

  console.log(category?.data?.users);
  if (isLoading) return <Spinner />;
  if (!category?.data?.users?.length)
    return <Empty resourceName="consultation" />;

  return (
    <Menus>
      <Table columns="2fr  2.4fr 1.4fr  3.2rem">
        <Table.Header>
          <div>User Name</div>
          <div>Phone Number</div>
          <div>Skills</div>
          <div></div>
        </Table.Header>

        <Table.Body
          data={category?.data?.users}
          render={(category) => (
            <CategoryRow key={category.id} category={category} />
          )}
        />

        <Table.Footer>
          {/* <Pagination
            currentPage={currentPage}
            pageCount={pageCount}
            onPageChange={handlePageChange}
          /> */}
        </Table.Footer>
      </Table>
    </Menus>
  );
}

export default CategoryTable;
