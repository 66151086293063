import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Row from "../ui/Row";
import Heading from "../ui/Heading";
import Spinner from "../ui/Spinner";
import { useTeamPlayers } from "../Feature/Teams/useTeamPlayers";
import Empty from "../ui/Empty";
import Pagination from "../ui/Pagination";
import Table from "../ui/Table";
import TeamPlayersRow from "../Feature/Teams/TeamPlayersRow";
import Menus from "../ui/Menus";

const TeamPlayers = () => {
  const { teamId } = useParams();
  const { TeamPlayers, isLoading, error, refetchTeamPlayers } =
    useTeamPlayers(teamId);
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = TeamPlayers?.data?.pagination?.total || 1;

  const handlePageChange = async (page) => {
    setCurrentPage(page); // Update the currentPage state
    await setCurrentPage(page); // Await the state update
    await refetchTeamPlayers({ page, teamId }); // Pass the updated page value to refetchCategory
  };

  console.log(TeamPlayers?.data?.TeamPlayers);
  if (isLoading) return <Spinner />;
  if (!TeamPlayers?.players?.length)
    return <Empty resourceName="Team Players" />;

  return (
    <>
      <Row type="horizontal">
        <Heading as="h1">TeamPlayers Players</Heading>
      </Row>
      <Menus>
        <Table columns="2fr 1fr 1fr 1fr .6fr .8fr .6fr  ">
          <Table.Header>
            <div>Player </div>
            <div>Phone Number </div>
            <div>Skills </div>
            <div>Age</div>
            <div>Nationality</div>
            <div>PlayerNumber</div>
            <div>Place Of Player</div>
          </Table.Header>

          <Table.Body
            data={TeamPlayers?.players}
            render={(TeamPlayers) => (
              <TeamPlayersRow key={TeamPlayers.id} TeamPlayers={TeamPlayers} />
            )}
          />

          <Table.Footer>
            <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              onPageChange={handlePageChange}
            />
          </Table.Footer>
        </Table>
      </Menus>{" "}
    </>
  );
};

export default TeamPlayers;
