import React from "react";
// import "./ChampionChipGroupList.css";
import styled from "styled-components";
import ChampionShipGroupItem from "./ChampionShipGroupItem";
const ChampionChipGroupList = ({ groups }) => {
  console.log(groups);

  return (
    <GroupList className="group-list">
      {groups.groups.map((group) => (
        <ChampionShipGroupItem key={group.id} groupItem={group} />
      ))}
    </GroupList>
  );
};

export default ChampionChipGroupList;
const GroupList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  height: 100%;
  place-items: center;
`;
