import styled from "styled-components";
import { format, isToday } from "date-fns";
import {
  HiArrowDownOnSquare,
  HiArrowUpOnSquare,
  HiEye,
  HiPencil,
  HiTrash,
} from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";

import Tag from "../../ui/Tag";
import Table from "../../ui/Table";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import ConfirmDelete from "../../ui/ConfirmDelete";
// import { useDeleteConsultation } from "./useDeleteConsultation";
import useAuth from "../../hooks/useAuth";
import { deleteChampionship } from "../../services/apiChampionship";
import { useEffect, useState } from "react";
const Cabin = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-600);
  font-family: "Sono";
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;

const Amount = styled.div`
  font-family: "Sono";
  font-weight: 500;
`;

const StackedImage = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  /* border-radius: 50%; */
  /* width: 5rem; */
  /* height: 5rem; */
  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;
const ImageContainer = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-size: cover;
  background-image: cover;
  object-fit: cover;
`;

function ChampionshipReqestsRow({
  Championship,
  handleReject,
  handleApprove,
  teamsCount,
}) {
  const { id, teamName, teamImage, coachName, ibanNumber, coachPhoneNumber } =
    Championship;
  console.log(teamName, teamImage, coachName, ibanNumber, coachPhoneNumber);

  const [ibanNumberNew, setIbanNumberNew] = useState("");
  const [disableApprove, setDisableApprove] = useState(false);
  useEffect(() => {
    // if (teamsCount === 16) {
    //   setDisableApprove(true);
    // } else {
    //   setDisableApprove(false);
    // }

    if (ibanNumber === "AE090975443675520000001") {
      setIbanNumberNew("Ghaith Iban");
    } else if (ibanNumber === "AE640500000000019162142") {
      setIbanNumberNew("Anas Iban");
    } else {
      setIbanNumberNew("كاش");
    }
  }, [
    // teamsCount,
    ibanNumber,
  ]);

  const navigate = useNavigate();
  // const { deleteConsultation, isDeleting } = useDeleteConsultation();
  console.log();
  const statusToTagName = {
    "Group Stage": "silver",
    open: "blue",
    ended: "green",
  };
  return (
    <Table.Row>
      <StackedImage>
        <ImageContainer src={teamImage || "no image"} />
        <Stacked>
          <span>{teamName}</span>
        </Stacked>
      </StackedImage>
      <Stacked>
        <span>{coachName}</span>
      </Stacked>
      <Stacked>
        <span>{coachPhoneNumber}</span>
      </Stacked>
      <Stacked>
        <span>{ibanNumberNew}</span>
      </Stacked>
      <Stacked>
        <Approve
          disabled={disableApprove}
          className={`${
            disableApprove ? "approve-btn-disabled" : ""
          } approve-btn`}
          onClick={() => handleApprove(id)}
        >
          Approve
        </Approve>
      </Stacked>
      <Stacked>
        <RejectButton className="reject-btn" onClick={() => handleReject(id)}>
          Reject
        </RejectButton>
      </Stacked>
    </Table.Row>
  );
}

export default ChampionshipReqestsRow;
const RejectButton = styled.div`
  color: white;
  background-color: #b91c1c;
  font-weight: 500;
  border-radius: 9999px;
  font-size: 0.875rem;
  padding: 0.425rem 1rem;
  text-align: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #991b1b;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.25rem #fca5a5;
  }
`;

const Approve = styled.div`
  color: white;
  background-color: #0ca853;
  font-weight: 500;
  border-radius: 9999px;
  font-size: 0.875rem;
  padding: 0.425rem 1rem;
  text-align: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #07914c;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.25rem #a5fcb2;
  }

  .approve-btn-disabled:hover {
    cursor: none;
    background-color: #444;
  }

  .approve-btn-disabled {
    cursor: none;
    background-color: #444;
  }

  .approve-btn-focus {
    cursor: none;
    box-shadow: none;
  }
`;
