import React from "react";
import Row from "../ui/Row";
import Heading from "../ui/Heading";
import TeamTable from "../Feature/Teams/TeamTable";

const Teams = () => {
  return (
    <>
      <Row type="horizontal">
        <Heading as="h1">All Teams</Heading>
        {/* <AddCategory /> */}
      </Row>
      <TeamTable />
    </>
  );
};

export default Teams;
