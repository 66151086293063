import Input from "../../ui/Input";
import Form from "../../ui/Form";
import Button from "../../ui/Button";
import FormRow from "../../ui/FormRow";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import { baseURL } from "../../api/API_Base_URL";
import styled from "styled-components";
import FileInput from "../../ui/FileInput";
import toast from "react-hot-toast";
import SpinnerMini from "../../ui/SpinnerMini";
function EditChampionshopForm({ onCloseModal, id }) {
  const [championShipName, setChampionShipName] = useState("");
  const [numberOfTeams, setNumberOfTeams] = useState(16);
  const [prize1, setPrize1] = useState("");
  const [prize2, setPrize2] = useState("");
  const [starDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [termsAndC, setTermsAndC] = useState("");
  const [entryPirce, setEntryPirce] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  console.log(
    championShipName,
    numberOfTeams,
    prize1,
    prize2,
    starDate,
    endDate,
    termsAndC,
    entryPirce,
    images
  );
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log({});

    e.preventDefault();

    try {
      const formData = new FormData();

      formData.append("championshipName", "hi");
      formData.append("numOfParticipants", numberOfTeams);
      formData.append("prize1", prize1);
      formData.append("prize2", prize2);
      formData.append("startDate", starDate);
      formData.append("endDate", endDate);
      formData.append("entryPrice", entryPirce);
      formData.append("termsAndConditions", termsAndC);
      images.forEach((image, index) => {
        formData.append(`image`, image);
      });

      console.log(formData);
      setIsLoading(true);
      const response = await fetch(`${baseURL}/editChampionship/${id}`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        toast.success("championShip created successfully");
      }
      const data = await response.json();
      console.log(data);
    } catch (error) {
      toast.error("championShip Failed ");

      console.error("Error", error);
    }
    setIsLoading(false);
  };
  return (
    <>
      <Form onSubmit={handleSubmit} type={onCloseModal ? "modal" : "regular"}>
        <FormRow label="ChampionShip Name">
          {<Label>Championship Name</Label>}
          <Input
            type="text"
            value={championShipName}
            onChange={(e) => setChampionShipName(e.target.value)}
          />
        </FormRow>
        <FormRow label=" numberOfTeams">
          {<Label>Number Of Teams</Label>}
          <Input type="number" value={numberOfTeams} />
        </FormRow>
        <FormRow label="prize1">
          {<Label>Prize 1</Label>}
          <Input
            type="number"
            value={prize1}
            onChange={(e) => setPrize1(e.target.value)}
          />
        </FormRow>
        <FormRow label="prize2">
          {<Label>Prize 2 </Label>}
          <Input
            type="number"
            value={prize2}
            onChange={(e) => setPrize2(e.target.value)}
          />
        </FormRow>
        <FormRow label="entryPirce">
          {<Label>Entry Pirce</Label>}
          <Input
            type="number"
            value={entryPirce}
            onChange={(e) => setEntryPirce(e.target.value)}
          />
        </FormRow>
        <FormRow label="starDate">
          {<Label>Star Date</Label>}
          <Input
            type="date"
            value={starDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </FormRow>
        <FormRow label="endDate">
          {<Label>End Date</Label>}
          <Input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </FormRow>
        <FormRow>
          {<Label>Terms And Conditinos</Label>}
          <Input
            type="text"
            value={termsAndC}
            onChange={(e) => setTermsAndC(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <Label>Add Championship Image</Label>
          <FileInput type="file" onChange={handleImageChange} />
        </FormRow>

        <FormRow>
          <Button
            variation="secondary"
            type="reset"
            onClick={() => onCloseModal?.()}
          >
            Cancel
          </Button>
          <Button>
            {/* Create new ChampionShip */}
            {isLoading ? <SpinnerMini /> : "Create new ChampionShip"}
          </Button>
        </FormRow>
      </Form>
    </>
  );
}

export default EditChampionshopForm;

const Label = styled.label`
  font-weight: 500;
`;
