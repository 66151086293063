import { useForm } from "react-hook-form";
import { useState } from "react";
import { userSignup } from "../../services/apiAuth";

import useAuth from "../../hooks/useAuth";
import FormRow from "../../ui/FormRow";
import Form from "../../ui/Form";
import RadioButton from "../../ui/RadioButton";
import Button from "../../ui/Button";
import Input from "../../ui/Input";

// Email regex: /\S+@\S+\.\S+/

function SignupForm() {
  const { auth } = useAuth();
  console.log(auth.token);
  const { register, formState, getValues, handleSubmit, reset, setValue } =
    useForm();
  const { errors } = formState;
  const [isLoading, setIsLoading] = useState(false); // Correct usage of useState hook

  const onSubmit = async ({ userName, password }) => {
    try {
      console.log(
        JSON.stringify({
          userName: userName,
          password: password,
        })
      );
      setIsLoading(true);
      console.log("auth token", auth.token);
      await userSignup({ token: auth.token }, userName, password, setIsLoading); // Call userSignup function
      reset(); // Reset form on successful signup
    } catch (error) {
      console.error("Signup failed:", error.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormRow label="User Name" error={errors?.name?.message}>
        <Input
          type="text"
          id="name"
          disabled={isLoading}
          {...register("userName", { required: "This field is required" })}
        />
      </FormRow>

      <FormRow
        label="Password (min 8 characters)"
        error={errors?.password?.message}
      >
        <Input
          type="password"
          id="password"
          disabled={isLoading}
          {...register("password", {
            required: "This field is required",
            minLength: {
              value: 8,
              message: "Password needs a minimum of 8 characters",
            },
          })}
        />
      </FormRow>

      <FormRow>
        {/* type is an HTML attribute! */}
        <Button
          variation="secondary"
          type="reset"
          disabled={isLoading}
          onClick={reset}
        >
          Cancel
        </Button>
        <Button disabled={isLoading}>Create new user</Button>
      </FormRow>
    </Form>
  );
}

export default SignupForm;
