import Table from "../../ui/Table";
import Menus from "../../ui/Menus";
import Empty from "../../ui/Empty";

import { useTeam } from "./useTeam";
import Spinner from "../../ui/Spinner";
import Pagination from "../../ui/Pagination";
import TeamRow from "./TeamRow";
import { useState } from "react";
import { PAGE_SIZE } from "../../utils/constants";

function TeamTable() {
  const { team, isLoading, error, refetchTeam } = useTeam();
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = team?.data?.pagination?.total || 1;

  const handlePageChange = async (page) => {
    setCurrentPage(page); // Update the currentPage state
    await setCurrentPage(page); // Await the state update
    await refetchTeam({ page }); // Pass the updated page value to refetchCategory
  };

  console.log(team?.data?.teams);
  if (isLoading) return <Spinner />;
  if (!team?.data?.teams?.length) return <Empty resourceName="consultation" />;

  return (
    <Menus>
      <Table columns="2.4fr 2.4fr 2fr  1.4fr  3.2rem">
        <Table.Header>
          <div>Team Name </div>
          <div>Coach Name </div>
          <div>Coach Phone Number </div>
          <div>Skills</div>
          <div></div>
        </Table.Header>

        <Table.Body
          data={team?.data?.teams}
          render={(team) => <TeamRow key={team.id} team={team} />}
        />

        <Table.Footer>
          {/* <Pagination
            currentPage={currentPage}
            pageCount={pageCount}
            onPageChange={handlePageChange}
          /> */}
        </Table.Footer>
      </Table>
    </Menus>
  );
}

export default TeamTable;
