import React, { useEffect, useState } from "react";
import "./BattelDetails.css";
import { useParams } from "react-router-dom";
import useFetchData from "../../services/useFetchData";
import { baseURL } from "../../api/API_Base_URL";
import Spinner from "../../ui/Spinner";
import Empty from "../../ui/Empty";
import BattelDetailsInformation from "./BattelDetailsInformation";
import Round from "./Round";
import { HiTrophy } from "react-icons/hi2";

// import BattelDetailsInformation from "./BattelDetailsInformation";
const BattelDetails = () => {
  const { championshipId } = useParams();
  console.log(championshipId);
  const [rounds, setRounds] = useState([]);
  const [firstWinner, setFirstWinner] = useState("لم يحدد بعد");
  const [secondWinner, setSecondWinner] = useState("لم يحدد بعد");

  const [championshipName, setChampionshipName] = useState("");
  const [championshipStatus, setChampionshipStatus] = useState("");
  const { data, isLoading, error } = useFetchData(
    `${baseURL}/getTree/${championshipId}`
  );
  useEffect(() => {
    if (data && data.championship && data.championship.rounds) {
      setRounds(data.championship.rounds);
      setChampionshipName(data.championship.championshipName);
      setChampionshipStatus(
        data.championship.status === "Ended" ? "منتهية" : "دوري الاقصاء"
      );
      setFirstWinner(data.championship.firstWinner);
      setSecondWinner(data.championship.secondWinner);
    }
  }, [data]);
  if (isLoading) return <Spinner />;
  if (error) return <Empty />;
  if (rounds)
    return (
      <div className="champion">
        <BattelDetailsInformation
          championshipName={championshipName}
          championshipStatus={championshipStatus}
        />
        <div className="rounds-container">
          {rounds.map((rounds, index) => (
            <Round key={index} round={rounds} roundIndex={index} />
          ))}
        </div>
        <div className="winner-teams">
          <div className="winner-teams-first-winner">
            <div className="winner-teams-winner-item">
              <p>الفائز الاول </p>
              <HiTrophy />
            </div>
            <p>{firstWinner}</p>
          </div>
          <div className="winner-teams-second-winner">
            <div className="winner-teams-winner-item">
              <p>الفائز الثاني </p>
              <HiTrophy />
            </div>
            <p>{secondWinner}</p>
          </div>
        </div>
      </div>
    );
};
export default BattelDetails;
