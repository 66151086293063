// AllGroupMatches.js
import React from "react";
import "./AllGroupMatches.css";

const AllGroupMatches = ({ matches, onMatchSelect, selectedMatchId }) => {
  console.log(selectedMatchId, "hsh");
  return (
    <div className="all-group-matches">
      <table className="all-group-matches-table">
        <thead className="all-group-matches-thead">
          <tr className="all-group-matches-tr">
            <th>Match</th>
            <th>team1</th>
            <th>skills</th>

            <th>skills</th>
            <th>team2</th>
          </tr>
        </thead>
        {matches?.map((match) => (
          <tbody className="xs">
            <tr
              key={match.id}
              className={` all-matches-table-row ${
                selectedMatchId === match.id
                  ? "selected"
                  : match.location && match.stad && match.winner
                  ? "match-end"
                  : ""
              } `}
              onClick={() => onMatchSelect(match)}
            >
              <td>{match.id}</td>
              <td className="all-group-matches-team-indentity">
                <img
                  className="all-group-matches-team-image"
                  src={match.team1.imagePath || "defualt"}
                  alt="Team 1"
                />
                <p> {match.team1.teamName}</p>
              </td>
              <td>{match.team1.points}</td>

              <td>{match.team2.points}</td>
              <td className="all-group-matches-team-indentity">
                <img
                  className="all-group-matches-team-image"
                  src={match.team1.imagePath || "defualt"}
                  alt="Team 1"
                />
                <p> {match.team2.teamName}</p>
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
};
export default AllGroupMatches;
