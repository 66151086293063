import styled from "styled-components";

const FileInput = styled.input.attrs({ type: "file" })`
  font-size: 1.4rem;
  border-radius: var(--border-radius-sm);

  &::file-selector-button {
    font: inherit;
    font-weight: 500;
    padding: 0.8rem 1.2rem;
    margin-right: 1.2rem;
    border-radius: var(--border-radius-sm);
    border: none;
    color: var(--color-brand-50);
    background-color: var(--color-brand-600);
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;

    &:hover {
      background-color: var(--color-brand-700);
    }
  }
`;

export default FileInput;
// images: data.image,
// const propertyData = {
//   images: Array.isArray(data.image) ? data.image : [],
//   descriptions: [
//     {
//       en: { content: data.enDescription },
//       fr: { content: data.arDescription },
//     },
//   ],
//   en: { name: data.en, bio: data.arBio },
//   fr: { name: data.fr, bio: data.enBio },
//   no_bedrooms: data.no_bedrooms,
//   no_bathrooms: data.no_bathrooms,
//   space: data.space,
//   price: data.price,
//   category_id: categoryId,

//   sub_category_id: subCategoryId,
//   payment_terms: [peymentTerms],
//   location: {
//     governorate_id: governorate,
//     lat: `${location.lat}`,
//     lng: `${location.lng}`,
//     en: { address: data.enAddress },
//     fr: { address: data.arAddress },
//   },
// };
