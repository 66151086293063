import styled from "styled-components";
import { format, isToday } from "date-fns";
import {
  HiArrowDownOnSquare,
  HiArrowUpOnSquare,
  HiEye,
  HiPencil,
  HiTrash,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Tag from "../../ui/Tag";
import Table from "../../ui/Table";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import ConfirmDelete from "../../ui/ConfirmDelete";
// import { useDeleteConsultation } from "./useDeleteConsultation";
import useAuth from "../../hooks/useAuth";
import { deleteConsultation } from "../../services/apiConsultation";
import ConsultationForm from "./ConsultationForm";
import EditConsultationForm from "./EditConsultationForm";
import { useDeleteConsultation } from "./useDeleteConsultation";
const Cabin = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-600);
  font-family: "Sono";
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;

const Amount = styled.div`
  font-family: "Sono";
  font-weight: 500;
`;

function ConsultationRow({ consultation }) {
  const { id, name, phone, email, status } = consultation;
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { deleteConsultation: deleteConsultationNew, isDeleting } =
    useDeleteConsultation();
  console.log();
  const statusToTagName = {
    unconfirmed: "silver",
    pending: "blue",
    confirmed: "green",
  };
  return (
    <Table.Row>
      <Cabin>{name}</Cabin>

      <Stacked>
        <span>{name}</span>
        <span>{email}</span>
      </Stacked>
      <Stacked>
        <span>{phone}</span>
      </Stacked>

      <Tag type={statusToTagName[status]}>{status.replace("-", " ")}</Tag>

      <Modal>
        <Menus.Menu>
          <Menus.Toggle id={id} />
          <Menus.List id={id}>
            <Menus.Button
              icon={<HiEye />}
              onClick={() => navigate(`/consultation/${id}`)}
            >
              See details
            </Menus.Button>

            {status === "unconfirmed" && (
              <Menus.Button
                icon={<HiArrowDownOnSquare />}
                onClick={() => navigate(`/checkin/${id}`)}
              >
                Check in
              </Menus.Button>
            )}
            <Modal.Open opens="edit">
              <Menus.Button icon={<HiPencil />}>Edit</Menus.Button>
            </Modal.Open>

            <Modal.Open opens="delete">
              <Menus.Button icon={<HiTrash />}>Delete booking</Menus.Button>
            </Modal.Open>
          </Menus.List>
          <Modal.Window name="edit">
            <EditConsultationForm id={id} />
          </Modal.Window>
        </Menus.Menu>

        <Modal.Window name="delete">
          <ConfirmDelete
            resourceName="Consultation"
            // disabled={isDeleting}
            // onConfirm={() => deleteConsultation({ token: auth.token }, id)}
            onConfirm={() => deleteConsultationNew(id)}
          />
        </Modal.Window>
      </Modal>
    </Table.Row>
  );
}

export default ConsultationRow;
