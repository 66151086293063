import styled from "styled-components";
import { format, isToday } from "date-fns";
import {
  HiArrowDownOnSquare,
  HiArrowUpOnSquare,
  HiEye,
  HiPencil,
  HiTrash,
} from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import EditChampionshopForm from "./EditChampionshopForm";
import Tag from "../../ui/Tag";
import Table from "../../ui/Table";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import ConfirmDelete from "../../ui/ConfirmDelete";
// import { useDeleteConsultation } from "./useDeleteConsultation";
import useAuth from "../../hooks/useAuth";
import { deleteChampionship } from "../../services/apiChampionship";
const Cabin = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-600);
  font-family: "Sono";
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;

const Amount = styled.div`
  font-family: "Sono";
  font-weight: 500;
`;

const StackedImage = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  /* border-radius: 50%; */
  /* width: 5rem; */
  /* height: 5rem; */
  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;
const ImageContainer = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-size: cover;
  background-image: cover;
  object-fit: cover;
`;

function ChampionshipRow({ Championship }) {
  const { id, championshipName, status, imagePath } = Championship;

  const navigate = useNavigate();
  const { auth } = useAuth();
  // const { deleteConsultation, isDeleting } = useDeleteConsultation();
  console.log();
  const statusToTagName = {
    "Group Stage": "silver",
    open: "blue",
    ended: "green",
  };
  return (
    <Table.Row>
      {/* <Cabin>{id}</Cabin> */}
      <StackedImage>
        <ImageContainer src={imagePath || "no image"} />
        <Stacked>
          <span>{championshipName}</span>
        </Stacked>
      </StackedImage>
      <Tag type={statusToTagName[status]}>{status.replace("-", " ")}</Tag>
      <Stacked>
        <Link to={`/all-championships/championship-requests/${id}`}>
          طلبات الانضمام
        </Link>
      </Stacked>
      <Stacked>
        <Link to={`/all-championships/championship-groups/${id}`}>
          دوري المجموعات
        </Link>
      </Stacked>
      <Stacked>
        <Link to={`/all-championships/championship-elimination/${id}`}>
          دوري الاقصاء
        </Link>
      </Stacked>
      <Modal>
        <Menus.Menu>
          <Menus.Toggle id={id} />
          <Menus.List id={id}>
            <Modal.Open opens="edit">
              <Menus.Button icon={<HiPencil />}>Edit Championship</Menus.Button>
            </Modal.Open>

            <Modal.Open opens="delete">
              <Menus.Button icon={<HiTrash />}>
                Delete Championship
              </Menus.Button>
            </Modal.Open>
          </Menus.List>
          <Modal.Window name="edit">
            <EditChampionshopForm id={id} />
          </Modal.Window>
        </Menus.Menu>

        <Modal.Window name="delete">
          <ConfirmDelete
            resourceName="Championship"
            // disabled={isDeleting}
            onConfirm={() => deleteChampionship(id)}
          />
        </Modal.Window>
      </Modal>
    </Table.Row>
  );
}

export default ChampionshipRow;
