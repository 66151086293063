import styled from "styled-components";
import { forwardRef } from "react";

const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  justify-content: center;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem;
`;

const RadioInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const RadioCustom = styled.span`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 2px solid var(--color-grey-800);
  margin-right: 0.5rem;
  transition: background-color 0.5s ease;

  ${RadioInput}:checked + & {
    background-color: var(--color-green-100);
    border-color: var(--color-green-100);
  }
`;

const RadioText = styled.span`
  color: var(--color-grey-800);
  transition: color 0.5s ease;
`;

const RadioButton = forwardRef(({ options, ...rest }, ref) => {
  return (
    <RadioWrapper>
      {options.map((option, index) => (
        <RadioLabel key={index}>
          <RadioInput
            type="radio"
            value={option.value}
            {...rest} // Pass other props like onChange, onBlur, etc.
            ref={ref} // Forward the ref to the input element
          />
          <RadioCustom />
          <RadioText>{option.label}</RadioText>
        </RadioLabel>
      ))}
    </RadioWrapper>
  );
});
export default RadioButton;
