import styled from "styled-components";

import ConsultationDataBox from "./ConsultationDataBox";
import Row from "../../ui/Row";
import Heading from "../../ui/Heading";
import Tag from "../../ui/Tag";
import ButtonGroup from "../../ui/ButtonGroup";
import Button from "../../ui/Button";
import ButtonText from "../../ui/ButtonText";

import { useMoveBack } from "../../hooks/useMoveBack";
import Spinner from "../../ui/Spinner";
import { useNavigate } from "react-router-dom";
import { HiArrowUpOnSquare } from "react-icons/hi2";
// import { useCheckout } from "../check-in-out/useCheckout";
import Modal from "../../ui/Modal";
import ConfirmDelete from "../../ui/ConfirmDelete";
import Empty from "../../ui/Empty";
import { useDeleteConsultation } from "./useDeleteConsultation";
import { useConsultation } from "./useConsultation";
import useAuth from "../../hooks/useAuth";
import { deleteConsultation } from "../../services/apiConsultation";

const HeadingGroup = styled.div`
  display: flex;
  gap: 2.4rem;
  align-items: center;
`;

function ConsultationDetails() {
  const { consultation, isLoading } = useConsultation();
  //   const { checkout, isCheckingOut } = useCheckout();
  //   const { deleteBooking, isDeleting } = useDeleteConsultation();
  const { auth } = useAuth();
  console.log(auth.token);
  const moveBack = useMoveBack();
  const navigate = useNavigate();
  console.log(consultation);
  if (isLoading) return <Spinner />;
  if (!consultation.data.length) return <Empty resourceName="consultation" />;

  const {
    email,
    name,
    note,
    phone,
    status,
    id: consultationId,
  } = consultation.data;
  console.log(consultation.data);
  console.log(status);
  const statusToTagName = {
    pending: "blue",
    Confirm: "green",
    // "checked-out": "silver",
  };

  return (
    <>
      <Row type="horizontal">
        <HeadingGroup>
          <Heading as="h1">Consultation #{consultationId}</Heading>

          {/* <Tag type={statusToTagName[status]}>{status.replace("-", " ")}</Tag> */}
        </HeadingGroup>
        <ButtonText onClick={moveBack}>&larr; Back</ButtonText>
      </Row>

      <ConsultationDataBox booking={consultationId} />

      <ButtonGroup>
        {status === "unconfirmed" && (
          <Button onClick={() => navigate(`/checkin/${consultationId}`)}>
            Check in
          </Button>
        )}

        {/* {status === "checked-in" && (
          <Button
            icon={<HiArrowUpOnSquare />}
            onClick={() => checkout(consultationId)}
            disabled={isCheckingOut}
          >
            Check out
          </Button>
        )} */}

        <Modal>
          <Modal.Open opens="delete">
            <Button variation="danger">Delete booking</Button>
          </Modal.Open>

          <Modal.Window name="delete">
            <ConfirmDelete
              resourceName="booking"
              //   disabled={isDeleting}
              //   onConfirm={() =>
              //     deleteConsultation({ token: auth.token }, consultationId)
              //   }
            />
          </Modal.Window>
        </Modal>

        <Button variation="secondary" onClick={moveBack}>
          Back
        </Button>
      </ButtonGroup>
    </>
  );
}

export default ConsultationDetails;
