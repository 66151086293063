import React, { useState } from "react";
import { baseURL } from "../../api/API_Base_URL";
import usePut from "../../services/usePut";
import Form from "../../ui/Form";
import FormRow from "../../ui/FormRow";
import Input from "../../ui/Input";
import Button from "../../ui/Button";

const EditGroupTeamRate = ({ id, teamName }) => {
  const { putData, error: putError, isLoading: eventPutLoader } = usePut();

  const [formData3, setFormData3] = useState({ rate: "" });
  const handleUpateTeam1Skills = (e) => {
    e.preventDefault();
    console.log(`${baseURL}/editTeamRating/${id}`);
    putData(`${baseURL}/editTeamRating/${id}`, formData3, false);
  };
  return (
    <Form onSubmit={handleUpateTeam1Skills}>
      <FormRow>
        <label>Add Rate For {teamName}</label>
        <Input
          type="number"
          step="0.1"
          placeholder={teamName}
          value={formData3.rate}
          onChange={(e) => setFormData3({ rate: e.target.value })}
        />
        <Button type="submiy">Add Rate</Button>
      </FormRow>
    </Form>
  );
};

export default EditGroupTeamRate;
