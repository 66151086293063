import styled from "styled-components";
import { format, isToday } from "date-fns";
import {
  HiArrowDownOnSquare,
  HiArrowUpOnSquare,
  HiEye,
  HiPencil,
  HiTrash,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Tag from "../../ui/Tag";
import Table from "../../ui/Table";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import ConfirmDelete from "../../ui/ConfirmDelete";
// import { useDeleteConsultation } from "./useDeleteConsultation";
import useAuth from "../../hooks/useAuth";
import { deleteCategory } from "../../services/apiCategory";
import CategoryForm from "./CategoryForm";
import EditCategoryForm from "./EditCategoryForm";
const Cabin = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-600);
  font-family: "Sono";
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;

const Amount = styled.div`
  font-family: "Sono";
  font-weight: 500;
`;

const StackedImage = styled.div`
  display: flex;
  gap: 1rem;
  /* border-radius: 50%; */
  /* width: 5rem; */
  /* height: 5rem; */
  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;
const ImageContainer = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-size: cover;
  background-image: cover;
  object-fit: cover;
`;

function CategoryRow({ category }) {
  const { id, elo, imagePath, fullName, phoneNumber, email } = category;

  const navigate = useNavigate();
  const { auth } = useAuth();
  // const { deleteConsultation, isDeleting } = useDeleteConsultation();
  console.log();
  const statusToTagName = {
    unconfirmed: "silver",
    pending: "blue",
    confirmed: "green",
  };
  return (
    <Table.Row>
      {/* <Cabin>{id}</Cabin> */}

      <StackedImage>
        {/* <Stacked> */}
        <ImageContainer src={imagePath || "no image"} />
        {/* </Stacked> */}
        <Stacked>
          <span>{fullName}</span>
          <span>{email}</span>
        </Stacked>
      </StackedImage>

      <Stacked>
        <span>{phoneNumber}</span>
      </Stacked>
      <Stacked>
        <span>{elo}</span>
      </Stacked>

      {/* <Tag type={statusToTagName[status]}>{status.replace("-", " ")}</Tag> */}

      <Modal>
        <Menus.Menu>
          <Menus.Toggle id={id} />
          <Menus.List id={id}>
            {/* <Menus.Button
              icon={<HiEye />}
              onClick={() => navigate(`/category/${id}`)}
            >
              See details
            </Menus.Button> */}
            {/* 
            {status === "unconfirmed" && (
              <Menus.Button
                icon={<HiArrowDownOnSquare />}
                onClick={() => navigate(`/checkin/${id}`)}
              >
                Check in
              </Menus.Button>
            )} */}
            {/* <Modal.Open opens="edit">
              <Menus.Button icon={<HiPencil />}>Edit</Menus.Button>
            </Modal.Open> */}

            <Modal.Open opens="delete">
              <Menus.Button icon={<HiTrash />}>Delete User</Menus.Button>
            </Modal.Open>
          </Menus.List>
          {/* <Modal.Window name="edit">
            <EditCategoryForm id={id} />
          </Modal.Window> */}
        </Menus.Menu>

        <Modal.Window name="delete">
          <ConfirmDelete
            resourceName="category"
            // disabled={isDeleting}
            onConfirm={() => deleteCategory(id)}
          />
        </Modal.Window>
      </Modal>
    </Table.Row>
  );
}

export default CategoryRow;
