import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../services/useFetchData";
import { baseURL } from "../../api/API_Base_URL";
import Spinner from "../../ui/Spinner";
import Empty from "../../ui/Empty";
import usePost from "../../services/usePost";
import styled from "styled-components";
import {
  HiBellAlert,
  HiMiniBackspace,
  HiOutlineHomeModern,
} from "react-icons/hi2";
import Form from "../../ui/Form";
import FormRow from "../../ui/FormRow";
import Button from "../../ui/Button";
import Input from "../../ui/Input";
const HTHMatchDetails = () => {
  const { headToHeadMatchId } = useParams();
  console.log(headToHeadMatchId);
  const { data, isLoading, error } = useFetchData(
    `${baseURL}/getH2HMatchDetails/${headToHeadMatchId}`
  );
  console.log(data);
  const { postData, error: postError, isLoading: eventLoader } = usePost();
  const {
    data: team1Players,
    isLoading: team1PLayersLoader,
    error: team1PlayersError,
  } = useFetchData(
    `${baseURL}/getTeamPlayers/${data.head2HeadMatch?.team1.id}`
  );
  const {
    data: team2Players,
    isLoading: team2PLayersLoader,
    error: team2PlayersError,
  } = useFetchData(
    `${baseURL}/getTeamPlayers/${data.head2HeadMatch?.team2.id}`
  );
  const id = headToHeadMatchId;
  console.log(data?.head2HeadMatch?.id);
  const [formData, setFormData] = useState({
    Head2HeadMatch_id: "",
    team_id: "",
    user_id: "",
    type: "",
    time: "",
  });
  const handleCreateEvent = (event) => {
    event.preventDefault();

    console.log("Event Data", formData);
    postData(`${baseURL}/addH2HEvent`, formData, true);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
      Head2HeadMatch_id: headToHeadMatchId,
    });
  };
  if (isLoading) return <Spinner />;
  if (!data?.head2HeadMatch) return <Empty />;

  return (
    <StyledBookingDataBox>
      <Header>
        <div>
          <div>
            <img
              style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
              src={data.head2HeadMatch.team1.imagePath}
            />
            <p>{data?.head2HeadMatch.team1.teamName}</p>
          </div>
        </div>
        <p>VS</p>
        <div>
          <div>
            <img
              style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
              src={data.head2HeadMatch.team1.imagePath}
            />
            <p>{data?.head2HeadMatch.team2.teamName}</p>
          </div>
        </div>
      </Header>
      <Header2>
        <div>
          <p>Winner :</p>
          <p>{data.head2HeadMatch?.winner}</p>
        </div>
        <div>
          <p>Location :</p>
          <p>{data.head2HeadMatch?.location}</p>
        </div>
        <div>
          <p>stad :</p>
          <p>{data.head2HeadMatch?.stad}</p>
        </div>
        <div>
          <p>time :</p>
          <p>{data.head2HeadMatch?.time}</p>
        </div>
        <div>
          <p>date :</p>
          <p>{data.head2HeadMatch?.date}</p>
        </div>
      </Header2>
      <Form onSubmit={handleCreateEvent}>
        <FormRow>
          <label htmlFor="team_id">Select Team:</label>
          <StyledSelect
            name="team_id"
            id="team_id"
            onChange={handleChange}
            value={formData.teamId}
            required
          >
            <option value="">Select Team</option>
            <option value={data.head2HeadMatch?.team1.id}>
              {data.head2HeadMatch?.team1.teamName}
            </option>
            <option value={data.head2HeadMatch?.team2.id}>
              {data.head2HeadMatch?.team2.teamName}
            </option>
          </StyledSelect>
        </FormRow>
        <FormRow>
          <label htmlFor="user_id">Select Player:</label>
          <StyledSelect
            name="user_id"
            id="user_id"
            onChange={handleChange}
            value={formData.playerId}
            required
          >
            <option value="">Select Player</option>

            {Number(formData.team_id) ===
              Number(data.head2HeadMatch?.team1.id) &&
              team1Players.players.map((player) => (
                <option key={player.id} value={player.id}>
                  {player.fullName}
                </option>
              ))}
            {Number(formData.team_id) ===
              Number(data.head2HeadMatch?.team2.id) &&
              team2Players.players.map((player) => (
                <option key={player.id} value={player.id}>
                  {player.fullName}
                </option>
              ))}
          </StyledSelect>
        </FormRow>
        <FormRow>
          <label htmlFor="type">Select Card Type:</label>
          <StyledSelect
            name="type"
            id="type"
            onChange={handleChange}
            value={formData.type}
            required
          >
            <option value="">Select Card Type</option>
            <option value="Yellow Card">Yellow Card</option>
            <option value="Red Card">Red Card</option>
            <option value="Goal">Goal</option>
          </StyledSelect>
        </FormRow>
        <FormRow>
          <label htmlFor="time">Select Event Time:</label>
          <Input
            type="number"
            name="time"
            id="time"
            min="1"
            max="120"
            onChange={handleChange}
            value={formData.time}
            required
          />
          <label>From 1 to 120 Min</label>
        </FormRow>
        <FormRow>
          <Button type="submit">Create Event</Button>
        </FormRow>
      </Form>
      <Header style={{ marginTop: "2rem" }}>Events :</Header>
      {data.events?.map((event, index) => (
        <Header2 className="header3">
          <Row key={index}>
            <Element>
              <Column>
                <p>Player Name:</p>
                <p>{event.playerName}</p>
              </Column>
              <Column>
                <p>Team Name :</p>
                <p>{event.teamName}</p>
              </Column>
              <Column>
                <p>Time:</p>
                <p>{event.time}</p>
              </Column>
              <Column>
                <p>Type:</p>
                <p>{event.type}</p>
              </Column>
            </Element>
          </Row>
        </Header2>
      ))}
    </StyledBookingDataBox>
  );
};

export default HTHMatchDetails;

const StyledBookingDataBox = styled.section`
  /* Box */
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);

  overflow: hidden;
`;
const Element = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2rem;
`;

const Row = styled.div`
  width: 100%; /* Make sure each row takes full width */
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex: 1;
`;
const SubElement = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 25%;
`;
const Header = styled.header`
  background-color: var(--color-brand-500);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
`;
const Header2 = styled.header`
  background-color: var(--color-silver-100);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
  &.header3 {
    background-color: var(--color-silver-100);
  }
`;
const Section = styled.section`
  padding: 3.2rem 4rem 1.2rem;
`;

const Guest = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 1.6rem;
  color: var(--color-grey-500);

  & p:first-of-type {
    font-weight: 500;
    color: var(--color-grey-700);
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 3.2rem;
  border-radius: var(--border-radius-sm);
  margin-top: 2.4rem;

  background-color: ${(props) =>
    props.isPaid ? "var(--color-green-100)" : "var(--color-yellow-100)"};
  color: ${(props) =>
    props.isPaid ? "var(--color-green-700)" : "var(--color-yellow-700)"};

  & p:last-child {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
  }

  svg {
    height: 2.4rem;
    width: 2.4rem;
    color: currentColor !important;
  }
`;

const Footer = styled.footer`
  padding: 1.6rem 4rem;
  font-size: 1.2rem;
  color: var(--color-grey-500);
  text-align: right;
`;
const StyledSelect = styled.select`
  font-size: 1.4rem;
  padding: 0.8rem 1.2rem;
  border: 1px solid
    ${(props) =>
      props.type === "white"
        ? "var(--color-grey-100)"
        : "var(--color-grey-300)"};
  border-radius: var(--border-radius-sm);
  background-color: var(--color-grey-0);
  font-weight: 500;
  box-shadow: var(--shadow-sm);
`;
