import { Link } from "react-router-dom";
import AddChampionShip from "../Feature/CreateChampionShip/AddChampionShip";
import Heading from "../ui/Heading";
import Row from "../ui/Row";
import Button from "../ui/Button";
import HTHTable from "../Feature/HeadToHead/HTHTable";

function HTH() {
  return (
    <>
      <Row type="horizontal">
        <Heading as="h1">Head To Head Match</Heading>
        <AddChampionShip />
        <Link to="/head-to-head-requests">
          <Button>Head To Head Match Requests</Button>
        </Link>
      </Row>
      <HTHTable />
      {/* <ChampionshipTable /> */}
    </>
  );
}
export default HTH;
