import React from "react";
// import "./ChampionShipGroupItem.css";
import styled from "styled-components";
// import defualt from "../assets/defualt.jpg";
import { Link } from "react-router-dom";
const ChampionShipGroupItem = ({ groupItem }) => {
  console.log(groupItem.id);

  return (
    <GroupLink
      to={`/group-matches/${groupItem.id}`}
      className="group-teams-container-link"
    >
      <GroupItemContainer className="group-teams-container">
        <GroupNameHeader className="group-name-header">
          {groupItem.group}
        </GroupNameHeader>
        <GroupNameHeader className="group-name-header">Points</GroupNameHeader>
        <GroupNameHeader className="group-name-header">Goals</GroupNameHeader>

        {groupItem.teams.map((team, index) => (
          <React.Fragment key={index}>
            <GroupItemEntry className="group-team-entry">
              <GroupTeamDetails className="group-team-details">
                <GroupTeamImage
                  className="group-team-details-image"
                  src={team.imagePath || ""}
                  alt={team.teamName}
                />
                <div>{team.teamName}</div>
              </GroupTeamDetails>
            </GroupItemEntry>
            <GroupTeamCenter className="group-team-points ">
              {team.points}
            </GroupTeamCenter>
            <GroupTeamCenter className="group-team-goals  ">
              {team.goals}
            </GroupTeamCenter>
          </React.Fragment>
        ))}
      </GroupItemContainer>
    </GroupLink>
  );
};

export default ChampionShipGroupItem;

const GroupLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  &:hover {
    border: 2px solid #2e8b57;
    /* border-radius: 8px; */
  }
`;
const GroupItemEntry = styled.div`
  background-color: #242424;
  display: flex;
  align-items: center;
  border-radius: 3px;
  justify-content: space-between;
`;

const GroupItemContainer = styled.div`
  width: 450px;
  background-color: #2d2d2d;
  padding: 10px 14px;
  display: grid;
  grid-template-columns: 3fr 0.5fr 0.5fr;
  direction: ltr;
  gap: 10px;
  border-radius: 8px;
`;

const GroupNameHeader = styled.div`
  font-weight: bold;
  border-radius: 3px;
`;

const GroupTeamCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.group-team-points {
    background-color: #2e8b57;
    border-radius: 3px;
  }
`;

const GroupTeamDetails = styled.div`
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-inline: 5px;
`;

const GroupTeamImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
