import React from "react";
import "./BattelDetailsInformation.css";
const BattelDetailsInformation = ({ championshipName, championshipStatus }) => {
  return (
    <div className="battel-information-container">
      <div className="battel-name-status">
        <p>{championshipName}</p>
        <p>: اسم البطولة</p>
      </div>
      <div className="battel-name-status">
        <p>{championshipStatus}</p>
        <p>: حالة البطولة</p>
      </div>
    </div>
  );
};

export default BattelDetailsInformation;
