import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetchData from "../../services/useFetchData";
import { baseURL } from "../../api/API_Base_URL";
import Spinner from "../../ui/Spinner";
import Empty from "../../ui/Empty";
import usePost from "../../services/usePost";
import styled from "styled-components";
import FileInput from "../../ui/FileInput";
import toast from "react-hot-toast";

import {
  HiBellAlert,
  HiMiniBackspace,
  HiOutlineHomeModern,
} from "react-icons/hi2";
import Form from "../../ui/Form";
import FormRow from "../../ui/FormRow";
import Button from "../../ui/Button";
import Input from "../../ui/Input";
import usePut from "../../services/usePut";
import SpinnerMini from "../../ui/SpinnerMini";
export const EditHTHMatch = () => {
  const { headToHeadMatchId } = useParams();
  const { data, isLoading, error } = useFetchData(
    `${baseURL}/getH2HMatchDetails/${headToHeadMatchId}`
  );
  const [loader, setLoader] = useState(false);
  const [err, setErr] = useState(null);

  const [time, setTime] = useState("");
  const [winner, setWinner] = useState("");
  const [stad, setStad] = useState("");
  const [location, setlocation] = useState("");
  const [date, setDate] = useState("");
  const [images, setImages] = useState([]);
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
  };
  const handleSubmitNew = async (e) => {
    e.preventDefault();
    console.log(time, winner, stad, date, location, images);

    try {
      const formData = new FormData();

      formData.append("date", date);
      formData.append("time", time);
      formData.append("winner", winner);
      formData.append("location", location);
      formData.append("stad", stad);
      images.forEach((image) => {
        formData.append("images[]", image);
      });

      console.log(formData);
      setLoader(true);
      const response = await fetch(
        `${baseURL}/editH2HMatch/${headToHeadMatchId}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        toast.success("edit head to head match  edited successfully");
      }
      const backendData = await response.json();
      console.log(backendData);
    } catch (error) {
      toast.error("head to head match  Failed ");

      console.error("Error", error);
    }
    setLoader(false);
  };
  useEffect(() => {
    if (data?.head2HeadMatch) {
      setTime(data.head2HeadMatch.time || "");
      setlocation(data.head2HeadMatch.location || "");
      setDate(data.head2HeadMatch.date || "");
      setStad(data.head2HeadMatch.stad || "");
      setWinner(data.head2HeadMatch.winner || "");
    }
  }, [data]);
  const [formData2, setFormData2] = useState({ rate: "" });
  const [formData3, setFormData3] = useState({ rate: "" });
  const [formData4, setFormData4] = useState({ skills: "" });
  const { postData, error: postError, isLoading: eventLoader } = usePost();
  const { putData, error: putError, isLoading: eventPutLoader } = usePut();

  const {
    data: team1Players,
    isLoading: team1PLayersLoader,
    error: team1PlayersError,
  } = useFetchData(
    `${baseURL}/getTeamPlayers/${data?.head2HeadMatch?.team1.id}`
  );

  const {
    data: team2Players,
    isLoading: team2PLayersLoader,
    error: team2PlayersError,
  } = useFetchData(
    `${baseURL}/getTeamPlayers/${data?.head2HeadMatch?.team2.id}`
  );

  const handleUpatePlayerSkills = (playerId, e) => {
    e.preventDefault();

    const skillsInt = parseFloat(formData4.skills);
    putData(
      `${baseURL}/editUserSkills/${playerId}`,
      { skills: skillsInt },
      false
    );
  };
  const handleUpateTeam1Skills = (e) => {
    e.preventDefault();
    putData(
      `${baseURL}/editTeamRating/${data?.head2HeadMatch?.team1.id}`,
      formData2,
      false
    );
  };
  const handleUpateTeam2Skills = (e) => {
    e.preventDefault();

    putData(
      `${baseURL}/editTeamRating/${data?.head2HeadMatch?.team2.id}`,
      formData3,
      false
    );
  };

  const handleInputChange = (playerId, value) => {
    setFormData4((prevData) => ({
      ...prevData,
      skills: value,
    }));
  };

  if (isLoading) return <Spinner />;
  if (!data?.head2HeadMatch) return <Empty />;
  return (
    <StyledBookingDataBox>
      <Header>
        <div>
          <div>
            <img
              style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
              src={data.head2HeadMatch.team1.imagePath}
            />
            <p>{data?.head2HeadMatch.team1.teamName}</p>
          </div>
        </div>
        <p>VS</p>
        <div>
          <div>
            <img
              style={{ borderRadius: "50%", width: "5rem", height: "5rem" }}
              src={data.head2HeadMatch.team1.imagePath}
            />
            <p>{data?.head2HeadMatch.team2.teamName}</p>
          </div>
        </div>
      </Header>
      {/* <Header2>
        <div>
          <p>Winner :</p>
          <p>{data.head2HeadMatch?.winner}</p>
        </div>
        <div>
          <p>Location :</p>
          <p>{data.head2HeadMatch?.location}</p>
        </div>
        <div>
          <p>stad :</p>
          <p>{data.head2HeadMatch?.stad}</p>
        </div>
        <div>
          <p>time :</p>
          <p>{data.head2HeadMatch?.time}</p>
        </div>
        <div>
          <p>date :</p>
          <p>{data.head2HeadMatch?.date}</p>
        </div>
      </Header2> */}
      <Form onSubmit={handleSubmitNew}>
        <FormRow>
          <label> Location:</label>
          <Input
            type="text"
            value={location}
            onChange={(e) => setlocation(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <label> Stad:</label>
          <Input
            type="text"
            value={stad}
            onChange={(e) => setStad(e.target.value)}
          />
        </FormRow>{" "}
        <FormRow>
          <label> Time:</label>
          <Input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </FormRow>
        <FormRow>
          <label> Date:</label>
          <Input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </FormRow>{" "}
        <FormRow>
          <label> Winner:</label>
          <StyledSelect
            name="team_id"
            id="team_id"
            onChange={(e) => setWinner(e.target.value)}
            value={winner} // Use formData.winner for the value
          >
            <option value="">
              {`${data?.head2HeadMatch?.winner || "  Select Winner"}`}
            </option>{" "}
            {/* Set value to an empty string */}
            <option value={data.head2HeadMatch?.team1.id}>
              {data.head2HeadMatch?.team1.teamName}
            </option>
            <option value={data.head2HeadMatch?.team2.id}>
              {data.head2HeadMatch?.team2.teamName}
            </option>
          </StyledSelect>
        </FormRow>
        <FormRow>
          <FileInput type="file" onChange={handleImageChange} multiple />
        </FormRow>
        <FormRow>
          <Button type="submit">{loader ? <SpinnerMini /> : "Edit"}</Button>{" "}
        </FormRow>
      </Form>
      <Header2>
        <p>Add Teams Rate</p>
      </Header2>
      <Form onSubmit={handleUpateTeam1Skills}>
        <FormRow>
          <label>Add Rate For {data?.head2HeadMatch?.team1.teamName}</label>
          <Input
            type="number"
            step="0.1"
            value={formData2.rate}
            placeholder={data?.head2HeadMatch?.team1.teamName}
            onChange={(e) => setFormData2({ rate: e.target.value })}
          />
          <Button type="submiy">{putError ? <Spinner /> : "Add Rate"}</Button>
        </FormRow>
      </Form>{" "}
      <Form onSubmit={handleUpateTeam2Skills}>
        <FormRow>
          <label>Add Rate For {data?.head2HeadMatch?.team2.teamName}</label>
          <Input
            type="number"
            step="0.1"
            placeholder={data?.head2HeadMatch?.team2.teamName}
            value={formData3.rate}
            onChange={(e) => setFormData3({ rate: e.target.value })}
          />
          <Button type="submiy">{putError ? <Spinner /> : "Add Rate"}</Button>
        </FormRow>
      </Form>
      <Header2>
        <p>
          Add{" "}
          <span style={{ color: "#7f171f", fontWeight: "900" }}>
            {data?.head2HeadMatch?.team1.teamName}
          </span>{" "}
          Players Skills
        </p>
      </Header2>
      <>
        {team1Players?.players?.map((player) => (
          <Form
            onSubmit={(e) => handleUpatePlayerSkills(player.id, e)}
            key={player.id}
          >
            <FormRow>
              <Input
                type="number"
                step="0.1"
                value={formData4[player.id]?.skills}
                onChange={(e) => handleInputChange(player.id, e.target.value)}
                placeholder={player.fullName}
              />
              <Button type="submit">
                {putError ? <Spinner /> : "Add Skill"}
              </Button>
            </FormRow>
          </Form>
        ))}
      </>
      <Header2>
        <p>
          Add{" "}
          <span style={{ color: "#7f171f", fontWeight: "900" }}>
            {data?.head2HeadMatch?.team2.teamName}
          </span>{" "}
          Players Skills
        </p>
      </Header2>
      <>
        {team2Players?.players?.map((player) => (
          <Form
            onSubmit={(e) => handleUpatePlayerSkills(player.id, e)}
            key={player.id}
          >
            <FormRow>
              <Input
                type="number"
                step="0.1"
                value={formData4[player.id]?.skills}
                onChange={(e) => handleInputChange(player.id, e.target.value)}
                placeholder={player.fullName}
              />
              <Button type="submit">
                {putError ? <Spinner /> : "Add Skill"}
              </Button>
            </FormRow>
          </Form>
        ))}
      </>
    </StyledBookingDataBox>
  );
};
const Header = styled.header`
  background-color: var(--color-brand-500);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
`;
const StyledBookingDataBox = styled.section`
  /* Box */
  background-color: var(--color-grey-0);
  border: 1px solid var(--color-grey-100);
  border-radius: var(--border-radius-md);

  overflow: hidden;
`;
const Header2 = styled.header`
  background-color: var(--color-silver-100);
  padding: 2rem 4rem;
  color: #e0e7ff;
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 3.2rem;
    width: 3.2rem;
  }

  & div:first-child {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-weight: 600;
    font-size: 1.8rem;
  }

  & span {
    font-family: "Sono";
    font-size: 2rem;
    margin-left: 4px;
  }
  &.header3 {
    background-color: var(--color-silver-100);
  }
`;
const StyledSelect = styled.select`
  font-size: 1.4rem;
  padding: 0.8rem 1.2rem;
  border: 1px solid
    ${(props) =>
      props.type === "white"
        ? "var(--color-grey-100)"
        : "var(--color-grey-300)"};
  border-radius: var(--border-radius-sm);
  background-color: var(--color-grey-0);
  font-weight: 500;
  box-shadow: var(--shadow-sm);
`;
