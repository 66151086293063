import Input from "../../ui/Input";
import Form from "../../ui/Form";
import Button from "../../ui/Button";

import FormRow from "../../ui/FormRow";
import useAuth from "../../hooks/useAuth";
import FileInput from "../../ui/FileInput";
import { useState } from "react";
import Select from "../../ui/Select";
import Map from "../../ui/Map";
import { baseURL } from "../../api/API_Base_URL";
import styled from "styled-components";
function ProjectsForm({ onCloseModal }) {
  const { auth } = useAuth();
  const [space, setsSpace] = useState("");
  const [price, setPrice] = useState("");
  const [images, setImages] = useState([]);
  const [enName, setEnName] = useState("");
  const [frName, setfrName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [peymentTerms, setPeymentTerms] = useState([]);
  const [website, setWebsite] = useState("");
  const [enAddress, setEnBioAddress] = useState("");
  const [frAddress, setFrAddress] = useState("");
  const handleEnNameChange = (e) => {
    setEnName(e.target.value);
  };

  const handleArNameChange = (e) => {
    setfrName(e.target.value);
  };

  const categoryOptions = [
    { value: "", label: "Category" },
    { value: "1", label: "Buy" },
    { value: "2", label: "Rent" },
    { value: "3", label: "Off Plan" },
  ];

  const handleCategoryIdChange = (e) => {
    const selectedCategoryId = e.target.value;
    console.log(selectedCategoryId); // Log selected CategoryId to console
    setCategoryId(selectedCategoryId);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      peymentTerms.forEach((term) => {
        formData.append("payment_terms[]", term);
      });

      formData.append("en[name]", enName);
      formData.append("fr[name]", frName);
      formData.append("finish_date", space);
      formData.append("init_price", price);
      formData.append("location[governorate_id]", governorate);
      formData.append("en[address]", enAddress);
      formData.append("fr[address]", frAddress);
      //
      //
      //
      images.forEach((image, index) => {
        formData.append(`image[${index}]`, image);
      });

      console.log(formData);

      const response = await fetch(`${baseURL}/projects`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        body: formData,
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error", error);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit} type={onCloseModal ? "modal" : "regular"}>
        <FormRow label="Category">
          {<Label>Category</Label>}
          <Select
            options={categoryOptions}
            value={categoryId}
            onChange={handleCategoryIdChange}
          />
        </FormRow>

        <FormRow label="Finish Date">
          {<Label>Finish Date</Label>}
          <Input
            type="number"
            value={space}
            onChange={(e) => setsSpace(e.target.value)}
          />
        </FormRow>
        <FormRow label="Initial Price">
          {<Label>Initial Price</Label>}
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </FormRow>
        <FormRow label="English Name">
          {<Label>English Name</Label>}
          <Input type="text" value={enName} onChange={handleEnNameChange} />
        </FormRow>
        <FormRow label="الاسم بالعربية">
          {<Label>Arabic Name</Label>}
          <Input type="text" value={frName} onChange={handleArNameChange} />
        </FormRow>
        <FormRow label=" English Address">
          {<Label>English Address</Label>}
          <Input
            type="text"
            value={enAddress}
            onChange={(e) => setEnName(e.target.value)}
          />
        </FormRow>
        <FormRow label=" Arabic Address">
          {<Label>Arabic Address</Label>}
          <Input
            type="text"
            value={frAddress}
            onChange={(e) => setFrAddress(e.target.value)}
          />
        </FormRow>
        <FileInput type="file" onChange={handleImageChange} multiple />
        <FormRow>
          <Button
            variation="secondary"
            type="reset"
            onClick={() => onCloseModal?.()}
          >
            Cancel
          </Button>
          <Button>Create new Projects </Button>
        </FormRow>
      </Form>
    </>
  );
}

export default ProjectsForm;

const Label = styled.label`
  font-weight: 500;
`;
