import styled from "styled-components";

import PropertyDataBox from "./PropertyDataBox";
import Row from "../../ui/Row";
import Heading from "../../ui/Heading";
import Tag from "../../ui/Tag";
import ButtonGroup from "../../ui/ButtonGroup";
import Button from "../../ui/Button";
import ButtonText from "../../ui/ButtonText";

import { useMoveBack } from "../../hooks/useMoveBack";
import Spinner from "../../ui/Spinner";
import { useNavigate } from "react-router-dom";
import { HiArrowUpOnSquare } from "react-icons/hi2";
// import { useCheckout } from "../check-in-out/useCheckout";
import Modal from "../../ui/Modal";
import ConfirmDelete from "../../ui/ConfirmDelete";
import Empty from "../../ui/Empty";
import { useDeleteProperty } from "./useDeleteProperty";
import { useProperty } from "./useProperty";
import useAuth from "../../hooks/useAuth";
import { deleteProperty } from "../../services/apiProperty";

const HeadingGroup = styled.div`
  display: flex;
  gap: 2.4rem;
  align-items: center;
`;

function PropertyDetails() {
  const { property, isLoading } = useProperty();
  //   const { checkout, isCheckingOut } = useCheckout();
  //   const { deleteBooking, isDeleting } = useDeleteproperty();
  const { auth } = useAuth();
  console.log(auth.token);
  const moveBack = useMoveBack();
  const navigate = useNavigate();
  console.log(property);
  if (isLoading) return <Spinner />;
  if (!property.data.length) return <Empty resourceName="property" />;

  const { email, name, note, phone, status, id: propertyId } = property.data;
  console.log(property.data);
  console.log(status);
  const statusToTagName = {
    pending: "blue",
    Confirm: "green",
    // "checked-out": "silver",
  };

  return (
    <>
      <Row type="horizontal">
        <HeadingGroup>
          <Heading as="h1">property #{propertyId}</Heading>

          {/* <Tag type={statusToTagName[status]}>{status.replace("-", " ")}</Tag> */}
        </HeadingGroup>
        <ButtonText onClick={moveBack}>&larr; Back</ButtonText>
      </Row>

      <PropertyDataBox booking={propertyId} />

      <ButtonGroup>
        {status === "unconfirmed" && (
          <Button onClick={() => navigate(`/checkin/${propertyId}`)}>
            Check in
          </Button>
        )}

        {/* {status === "checked-in" && (
          <Button
            icon={<HiArrowUpOnSquare />}
            onClick={() => checkout(propertyId)}
            disabled={isCheckingOut}
          >
            Check out
          </Button>
        )} */}

        <Modal>
          <Modal.Open opens="delete">
            <Button variation="danger">Delete booking</Button>
          </Modal.Open>

          <Modal.Window name="delete">
            <ConfirmDelete
              resourceName="booking"
              //   disabled={isDeleting}
              //   onConfirm={() =>
              //     deleteproperty({ token: auth.token }, consultationId)
              //   }
            />
          </Modal.Window>
        </Modal>

        <Button variation="secondary" onClick={moveBack}>
          Back
        </Button>
      </ButtonGroup>
    </>
  );
}

export default PropertyDetails;
