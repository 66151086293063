import styled from "styled-components";
import { format, isToday } from "date-fns";
import {
  HiArrowDownOnSquare,
  HiArrowUpOnSquare,
  HiEye,
  HiPencil,
  HiTrash,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Tag from "../../ui/Tag";
import Table from "../../ui/Table";

// import { useDeleteConsultation } from "./useDeleteConsultation";
import useAuth from "../../hooks/useAuth";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import EditPrice from "./EditPrice";
import ConfirmDelete from "../../ui/ConfirmDelete";
import { deletePrice } from "../../services/apiPrice";
const Cabin = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-600);
  font-family: "Sono";
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;

const Amount = styled.div`
  font-family: "Sono";
  font-weight: 500;
`;

const StackedImage = styled.div`
  display: flex;
  gap: 1rem;
  /* border-radius: 50%; */
  /* width: 5rem; */
  /* height: 5rem; */
  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;
const ImageContainer = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-size: cover;
  background-image: cover;
  object-fit: cover;
`;

function PriceRow({ Price }) {
  console.log(Price);
  const { id, price, code } = Price;

  const navigate = useNavigate();
  const { auth } = useAuth();
  // const { deleteConsultation, isDeleting } = useDeleteConsultation();
  console.log();
  const statusToTagName = {
    unconfirmed: "silver",
    pending: "blue",
    confirmed: "green",
  };
  return (
    <Table.Row>
      <Stacked>
        <span>{price}</span>
      </Stacked>
      <Stacked>
        <span>{code}</span>
      </Stacked>
      <Modal>
        <Menus.Menu>
          <Menus.Toggle id={id} />
          <Menus.List id={id}>
            <Modal.Open opens="edit">
              <Menus.Button icon={<HiPencil />}> Edit Price</Menus.Button>
            </Modal.Open>

            <Modal.Open opens="delete">
              <Menus.Button icon={<HiTrash />}>Delete HTH Match</Menus.Button>
            </Modal.Open>
          </Menus.List>
          <Modal.Window name="edit">
            <EditPrice id={id} />
          </Modal.Window>
        </Menus.Menu>

        <Modal.Window name="delete">
          <ConfirmDelete
            resourceName="Consultation"
            // disabled={isDeleting}
            // onConfirm={() => deleteConsultation({ token: auth.token }, id)}
            onConfirm={() => deletePrice(id)}
          />
        </Modal.Window>
      </Modal>
    </Table.Row>
  );
}

export default PriceRow;
