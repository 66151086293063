import React from "react";
import Match from "./Match";
import "./Round.css"
const Round = ({ round,roundIndex }) => {
//   console.log(round);
  return (
    
     <div className={`round round-${roundIndex}`}>
      {round.matches.map((match, index) => (
     <>   <Match key={match.id} matchData={match} matchIndex={index} roundIndex={roundIndex} />
      <span className={`${match.team1 && match.team2 ? `match-horizental-line-${roundIndex}`: "match-horizental-line-2" }`}></span></>
      ))}
    </div>
    
  );
};

export default Round;
