import { useQuery } from "@tanstack/react-query";
import { getTeam } from "../../services/apiTeam";

export function useTeam(currentPage) {
  const {
    isLoading,
    data: team,
    error,
    refetch: refetchTeam,
  } = useQuery({
    queryKey: ["Team", currentPage],
    queryFn: async ({ queryKey }) => {
      const [, page] = queryKey;
      return await getTeam({ page });
    },
  });
  console.log(currentPage, "useTeam");
  return {
    team,
    isLoading,
    error,
    refetchTeam,
  };
}
