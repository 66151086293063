import AddHTHMatch from "../Feature/HeadToHead/AddHTHMatch";
import ChampionshipTable from "../Feature/CreateChampionShip/ChampionshipTable";
import Heading from "../ui/Heading";
import Row from "../ui/Row";

function Championship() {
  return (
    <>
      <Row type="horizontal">
        <Heading as="h1">All Users</Heading>
        <AddHTHMatch />
      </Row>
      <ChampionshipTable />
    </>
  );
}
export default Championship;
