import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import useAuth from "../hooks/useAuth";
import { login } from "../services/apiAuth";
import toast from "react-hot-toast";
import Heading from "../ui/Heading";
import Logo from "../ui/Logo";
import LoginForm from "../Feature/Authentication/LoginForm";

const LoginLayout = styled.main`
  min-height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 48rem;
  align-content: center;
  justify-content: center;
  gap: 3.2rem;
  background-color: var(--color-grey-50);
`;

const Login = () => {
  return (
    <LoginLayout>
      <Logo />
      <Heading as="h4">Log in to your account</Heading>

      {/* <form onSubmit={handleSubmit}>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          ref={userRef}
          autoComplete="off"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />

        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          required
        />
        <button> {isLoading ? "Logging in..." : "Login"}</button>
      </form> */}
      <LoginForm />
    </LoginLayout>
  );
};

export default Login;
