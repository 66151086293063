import styled from "styled-components";
import { format, isToday } from "date-fns";
import {
  HiArrowDownOnSquare,
  HiArrowUpOnSquare,
  HiEye,
  HiPencil,
  HiTrash,
} from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";

import Tag from "../../ui/Tag";
import Table from "../../ui/Table";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import ConfirmDelete from "../../ui/ConfirmDelete";
// import { useDeleteConsultation } from "./useDeleteConsultation";
import useAuth from "../../hooks/useAuth";
import { deleteChampionship } from "../../services/apiChampionship";
import { EditHTHMatch } from "./EditHTHMatch";
import { deleteHTH } from "../../services/apiHTH";
const Cabin = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-600);
  font-family: "Sono";
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;

const Amount = styled.div`
  font-family: "Sono";
  font-weight: 500;
`;

const StackedImage = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  /* border-radius: 50%; */
  /* width: 5rem; */
  /* height: 5rem; */
  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;
const ImageContainer = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-size: cover;
  background-image: cover;
  object-fit: cover;
`;

function HTHMatchRow({ HTH }) {
  const navigate = useNavigate();

  console.log(HTH);
  const { id, team1, time, team2, date, status } = HTH;
  const team1Name = team1.teamName;
  const team2Name = team2.teamName;
  const team1Image = team1.imagePath;
  const team2Image = team2.imagePath;
  const statusToTagName = {
    "Group Stage": "silver",
    open: "blue",
    ended: "green",
  };
  return (
    <Table.Row>
      {/* <Cabin>{id}</Cabin> */}
      <StackedImage>
        <ImageContainer src={team1Image || "no image"} />
        <Stacked>
          <span>{team1Name}</span>
        </Stacked>
      </StackedImage>
      <div>VS</div>
      <StackedImage>
        <ImageContainer src={team2Image || "no image"} />
        <Stacked>
          <span>{team2Name}</span>
        </Stacked>
      </StackedImage>
      <Stacked>{date}</Stacked>
      <Stacked>{time}</Stacked>
      <Stacked>{status}</Stacked>
      <Modal>
        <Menus.Menu>
          <Menus.Toggle id={id} />
          <Menus.List id={id}>
            <Menus.Button
              icon={<HiEye />}
              onClick={() => navigate(`/head-to-head-match-details/${id}`)}
            >
              See details
            </Menus.Button>

            <Menus.Button
              icon={<HiEye />}
              onClick={() => navigate(`/edit-head-to-head-match-details/${id}`)}
            >
              Edit Head to Head Match
            </Menus.Button>

            <Modal.Open opens="delete">
              <Menus.Button icon={<HiTrash />}>Delete HTH Match</Menus.Button>
            </Modal.Open>
          </Menus.List>
          <Modal.Window name="edit">
            <EditHTHMatch id={id} />
          </Modal.Window>
        </Menus.Menu>

        <Modal.Window name="delete">
          <ConfirmDelete
            resourceName="Consultation"
            // disabled={isDeleting}
            // onConfirm={() => deleteConsultation({ token: auth.token }, id)}
            onConfirm={() => deleteHTH(id)}
          />
        </Modal.Window>
      </Modal>
    </Table.Row>
  );
}

export default HTHMatchRow;
