import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();
  console.log(auth);
  // Check if auth is not yet available or if the user's role is allowed
  const isAuthAvailableAndRoleAllowed =
    Object.keys(auth).length !== 0 && containsObject(allowedRoles, auth.role);

  // Function to check if an object contains another object
  function containsObject(obj, target) {
    for (const key in target) {
      if (target.hasOwnProperty(key)) {
        if (!obj.hasOwnProperty(key) || obj[key] !== target[key]) {
          return false;
        }
      }
    }
    return true;
  }

  return isAuthAvailableAndRoleAllowed ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
