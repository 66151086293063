import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Form from "../../ui/Form";
import Button from "../../ui/Button";
import FormRow from "../../ui/FormRow";
import Input from "../../ui/Input";
import { useEditPrice } from "./useEditPrice";
import useAuth from "../../hooks/useAuth";

function EditPriceForm({ cabinToEdit = {}, onCloseModal, id }) {
  const { auth } = useAuth();

  const { isEditing, editPrice } = useEditPrice();
  const isWorking = isEditing;
  const {
    code: editId,
    price: { name: editEnName } = {},
    ...editValues
  } = cabinToEdit;
  const isEditSession = Boolean(editId);

  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: {
      ...editValues,
      code: "", // Include English name in defaultValues
      price: "", // Include French name in defaultValues
    },
  });
  const { errors } = formState;

  function onSubmit(data) {
    const PriceData = {
      code: data.code,
      price: data.price,
    };
    editPrice(
      { newPriceData: PriceData, id: id },
      {
        onSuccess: () => {
          reset();
          onCloseModal?.();
        },
      }
    );
  }

  function onError(errors) {
    console.log(errors);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)} type="modal">
      <FormRow label="code" error={errors?.fr?.message}>
        <Input
          type="text"
          id="code"
          disabled={isWorking}
          {...register("code", {
            required: "This field is required",
          })}
        />
      </FormRow>
      <FormRow label="price" error={errors?.fr?.message}>
        <Input
          type="number"
          id="price"
          disabled={isWorking}
          {...register("price", {
            required: "This field is required",
          })}
        />
      </FormRow>
      <FormRow>
        <Button
          variation="secondary"
          type="reset"
          onClick={() => onCloseModal?.()}
        >
          Cancel
        </Button>
        <Button disabled={isWorking}>
          {isEditSession ? "Edit Price" : "Edit Price"}
        </Button>
      </FormRow>
    </Form>
  );
}

export default EditPriceForm;
