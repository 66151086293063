import Input from "../../ui/Input";
import Form from "../../ui/Form";
import Button from "../../ui/Button";

import FormRow from "../../ui/FormRow";
import { useCreateProperty } from "./useCreateProperty";
import useAuth from "../../hooks/useAuth";
import FileInput from "../../ui/FileInput";
import { useState } from "react";
import Select from "../../ui/Select";
import Map from "../../ui/Map";
import { baseURL } from "../../api/API_Base_URL";
import styled from "styled-components";
function PropertyForm({ onCloseModal }) {
  const { auth } = useAuth();
  const [no_bedrooms, setNo_bedrooms] = useState("");
  const [no_bathrooms, setNo_bathrooms] = useState("");
  const [space, setsSpace] = useState("");
  const [price, setPrice] = useState("");
  const [locationn, setLocationn] = useState({ lat: null, lng: null });
  const [descriptions, setDescriptions] = useState([
    {
      en: { content: "" },
      fr: { content: "" },
    },
  ]);
  const [images, setImages] = useState([]);
  const [enName, setEnName] = useState("");
  const [frName, setfrName] = useState("");
  const [enBio, setEnBio] = useState("");
  const [frBio, setfrBio] = useState("");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [governorate, setGovernorate] = useState("");
  const [peymentTerms, setPeymentTerms] = useState([]);
  const [location, setLocation] = useState({
    governorate_id: "",
    en: { address: "" },
    fr: { address: "" },
  });

  const handleDescriptionChange = (index, language, value) => {
    setDescriptions((prevDescriptions) => {
      const updatedDescriptions = [...prevDescriptions];
      updatedDescriptions[index][language].content = value;
      return updatedDescriptions;
    });
  };

  const handleEnNameChange = (e) => {
    setEnName(e.target.value);
  };

  const handleArNameChange = (e) => {
    setfrName(e.target.value);
  };

  const handleEnBioChange = (e) => {
    setEnBio(e.target.value);
  };

  const handleArBioChange = (e) => {
    setfrBio(e.target.value);
  };

  const handleLocationChange = (field, value) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      [field]: value,
    }));
  };

  const categoryOptions = [
    { value: "", label: "Category" },
    { value: "1", label: "Buy" },
    { value: "2", label: "Rent" },
    { value: "3", label: "Off Plan" },
  ];
  const subCategoryOptions = [
    { value: "", label: "Sub Category" },
    { value: "1", label: "Vila" },
    { value: "2", label: "Apartment" },
    { value: "3", label: "an" },
  ];
  const governorateOptions = [
    { value: "", label: "Governorate" },
    { value: "1", label: "Buy" },
    { value: "2", label: "Rent" },
    { value: "3", label: "Off Plan" },
  ];
  const paymentTermsOptions = [
    { value: "", label: "Governorate" },
    { value: "1", label: "Buy" },
    { value: "2", label: "Rent" },
    { value: "3", label: "Off Plan" },
  ];

  const handleCategoryIdChange = (e) => {
    const selectedCategoryId = e.target.value;
    console.log(selectedCategoryId); // Log selected CategoryId to console
    setCategoryId(selectedCategoryId);
  };
  const handleSubCategoryIdChange = (e) => {
    const selectedSubCategoryId = e.target.value;
    console.log(selectedSubCategoryId); // Log selected CategoryId to console
    setSubCategoryId(selectedSubCategoryId);
  };
  const handleGovernorateChange = (e) => {
    const selectedGovernorate = e.target.value;
    console.log(selectedGovernorate);
    setGovernorate(selectedGovernorate);
  };
  const handlePaymentTermsChange = (e) => {
    const selectedPaymentTerms = e.target.value;
    console.log(selectedPaymentTerms);
    setPeymentTerms([selectedPaymentTerms]);
  };
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
  };
  const handleAddDescription = () => {
    setDescriptions((prevDescriptions) => [
      ...prevDescriptions,
      { en: { content: "" }, fr: { content: "" } },
    ]);
  };
  const handleSubmit = async (e) => {
    console.log(lat, lng, "handleSubmit");
    e.preventDefault();

    console.log({
      images: images,
      descriptions: descriptions,

      en: { name: enName, bio: enBio },
      fr: { name: frName, bio: frBio },
      no_bedrooms: no_bedrooms,
      no_bathrooms: no_bathrooms,
      space: space,
      price: price,
      category_id: categoryId,
      sub_category_id: subCategoryId,
      payment_terms: Array(peymentTerms),
      location: {
        governorate_id: governorate,
        lat: lat.lat.toString(),
        lng: lng.lng.toString(),
        en: { address: location.en.address },
        fr: { address: location.fr.address },
      },
    });

    e.preventDefault();
    console.log(Array(peymentTerms));
    console.log(descriptions);

    try {
      const formData = new FormData();

      peymentTerms.forEach((term) => {
        formData.append("payment_terms[]", term);
      });
      descriptions.forEach((description, index) => {
        formData.append(
          `descriptions[${index}][en][content]`,
          description.en.content
        );
        formData.append(
          `descriptions[${index}][fr][content]`,
          description.fr.content
        );
      });
      formData.append("en[name]", enName);
      formData.append("en[bio]", enBio);
      formData.append("fr[name]", frName);
      formData.append("fr[bio]", frBio);
      formData.append("no_bedrooms", no_bedrooms);
      formData.append("no_bathrooms", no_bathrooms);
      formData.append("space", space);
      formData.append("price", price);
      formData.append("category_id", categoryId);
      formData.append("sub_category_id", subCategoryId);
      formData.append("location[governorate_id]", governorate);
      formData.append("location[lat]", lat.lat.toString());
      formData.append("location[lng]", lng.lng.toString());
      formData.append("location[en][address]", location.en.address);
      formData.append("location[fr][address]", location.fr.address);
      images.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });

      console.log(formData);

      const response = await fetch(`${baseURL}/property`, {
        method: "POST",
        headers: {
          // Don't set Content-Type header, it will be automatically set by FormData
          Authorization: `Bearer ${auth.token}`,
        },
        body: formData,
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error", error);
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit} type={onCloseModal ? "modal" : "regular"}>
        <FormRow label="Category">
          {<Label>Category</Label>}
          <Select
            options={categoryOptions}
            value={categoryId}
            onChange={handleCategoryIdChange}
          />
        </FormRow>
        <FormRow label="Sub Category">
          {<Label>Sub Category</Label>}
          <Select
            options={subCategoryOptions}
            value={subCategoryId}
            onChange={handleSubCategoryIdChange}
          />
        </FormRow>
        <FormRow label="Number of Bedrooms">
          {<Label>Number of Bedrooms</Label>}
          <Input
            type="number"
            value={no_bedrooms}
            onChange={(e) => setNo_bedrooms(e.target.value)}
            required
          />{" "}
        </FormRow>
        <FormRow label="Number of Bathrooms">
          {<Label>Number of Bathrooms</Label>}
          <Input
            type="number"
            value={no_bathrooms}
            onChange={(e) => setNo_bathrooms(e.target.value)}
          />{" "}
        </FormRow>
        <FormRow label="Space">
          {<Label>Space</Label>}
          <Input
            type="number"
            value={space}
            onChange={(e) => setsSpace(e.target.value)}
          />{" "}
        </FormRow>
        <FormRow label="Price">
          {<Label>Price</Label>}
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />{" "}
        </FormRow>
        <FormRow label="English Name">
          {<Label>English Name</Label>}
          <Input type="text" value={enName} onChange={handleEnNameChange} />
        </FormRow>{" "}
        <FormRow label="الاسم بالعربية">
          {<Label>Arabic Name</Label>}
          <Input type="text" value={frName} onChange={handleArNameChange} />
        </FormRow>{" "}
        <FormRow label="English Bio">
          {<Label>Category</Label>}
          <Input type="text" value={enBio} onChange={handleEnBioChange} />
        </FormRow>{" "}
        <FormRow label=" Arabic Bio">
          {<Label>Category</Label>}
          <Input type="text" value={frBio} onChange={handleArBioChange} />
        </FormRow>{" "}
        <div style={{ display: "flex" }}>
          {descriptions.map((description, index) => (
            <div key={index}>
              <FormRow label="Description">
                {<Label>Description</Label>}
                <Input
                  type="text"
                  value={description.en.content}
                  onChange={(e) =>
                    handleDescriptionChange(index, "en", e.target.value)
                  }
                />
              </FormRow>
              <FormRow label="Arabic Description">
                {<Label>Arabic Description</Label>}
                <Input
                  type="text"
                  value={description.fr.content}
                  onChange={(e) =>
                    handleDescriptionChange(index, "fr", e.target.value)
                  }
                />
              </FormRow>
            </div>
          ))}
          <Button onClick={handleAddDescription}>Add Description</Button>
        </div>
        <FormRow label=" English Address">
          {<Label>English Address</Label>}
          <Input
            type="text"
            value={location.en.address}
            onChange={(e) =>
              handleLocationChange("en", {
                ...location.en,
                address: e.target.value,
              })
            }
          />
        </FormRow>
        <FormRow label=" Arabic Address">
          {<Label>Arabic Address</Label>}
          <Input
            type="text"
            value={location.fr.address}
            onChange={(e) =>
              handleLocationChange("fr", {
                ...location.fr,
                address: e.target.value,
              })
            }
          />
        </FormRow>
        <FormRow label="Governorate">
          {<Label>Governorate</Label>}
          <Select
            options={governorateOptions}
            value={governorate}
            onChange={handleGovernorateChange}
          />
        </FormRow>
        <FormRow label="Peyment Terms">
          {<Label>Peyment </Label>}
          <Select
            options={paymentTermsOptions}
            value={peymentTerms}
            onChange={handlePaymentTermsChange}
          />
        </FormRow>
        {/* <FormRow label="Cabin photo">
          <FileInput multiple accept="image/*" />
        </FormRow> */}
        <input type="file" onChange={handleImageChange} multiple />
        <FormRow>
          <Button
            variation="secondary"
            type="reset"
            onClick={() => onCloseModal?.()}
          >
            Cancel
          </Button>
          <Button>Create new Property </Button>
        </FormRow>
      </Form>
      <Map setLocation={setLocationn} setLat={setLat} setLng={setLng} />
    </>
  );
}

export default PropertyForm;

const Label = styled.label`
  font-weight: 500;
`;
