import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import { editPriceData } from "../../services/apiPrice";

export function useEditPrice() {
  const queryClient = useQueryClient();
  const { auth } = useAuth();
  const { mutate: editPrice, isLoading: isEditing } = useMutation({
    mutationFn: ({ newPriceData, id }) =>
      editPriceData(newPriceData, id, { token: auth.token }),
    onSuccess: () => {
      toast.success("Price successfully edited");
      queryClient.invalidateQueries({ queryKey: ["Price"] });
    },
    onError: (err) => toast.error(err.message),
  });

  return { isEditing, editPrice };
}
