// Logout.js
import { HiArrowRightOnRectangle } from "react-icons/hi2";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonIcon from "../../ui/ButtonIcon";
import SpinnerMini from "../../ui/SpinnerMini";
import useAuth from "../../hooks/useAuth";
import { logout } from "../../services/apiAuth";

const Logout = () => {
  const { auth, logoutAuth, updateAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogout = async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log(auth.token);
      // await logout({ token: auth.token }, setIsLoading, setError);
      updateAuth("");

      logoutAuth({});
      navigate("/login");
    } catch (error) {
      setIsLoading(false);
      console.error("Logout failed:", error);
    }
  };

  return (
    <ButtonIcon disabled={isLoading} onClick={handleLogout}>
      {!isLoading ? <HiArrowRightOnRectangle /> : <SpinnerMini />}
    </ButtonIcon>
  );
};

export default Logout;
