import { useState, useCallback } from "react";

const usePut = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const putData = useCallback(
    async (url, data = {}, useFormData = false, config = {}) => {
      setIsLoading(true);
      setError(null);
      setResponse(null);

      try {
        let body;
        let headers = {};

        // Check if the data should be wrapped in FormData
        if (useFormData) {
          const formData = new FormData();
          for (const key in data) {
            // If the value is an array, assume it's a file input
            if (Array.isArray(data[key])) {
              data[key].forEach((file, index) => {
                formData.append(`${key}[${index}]`, file);
              });
            } else {
              formData.append(key, data[key]);
            }
          }
          body = formData;
        } else {
          const isFormData = data instanceof FormData;
          body = isFormData ? data : JSON.stringify(data);
          headers = isFormData
            ? {}
            : { "Content-Type": "application/json", ...config.headers };
        }
        console.log(body);
        const response = await fetch(url, {
          method: "PUT",
          headers,
          body,
          ...config,
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Something went wrong!");
        }

        const responseData = await response.json();
        setResponse(responseData);
        console.log(response, "responseData");
      } catch (err) {
        setError(err.message || "Failed to update data");
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { putData, isLoading, error, response };
};

export default usePut;
