import React from "react";
import { useParams } from "react-router-dom";
import ChampionShipGroupList from "../Feature/Group/ChampionShipGroupList";
import { baseURL } from "../api/API_Base_URL";
import useFetchData from "../services/useFetchData";
import Spinner from "../ui/Spinner";
import Empty from "../ui/Empty";
const ChampionshipGroup = () => {
  const { championshipId } = useParams();
  const { data, isLoading, error } = useFetchData(
    `${baseURL}/getGroups/${championshipId}`
  );
  if (isLoading) {
    return <Spinner />;
  }
  if (error) {
    return <Empty error="Error with getting groups" />;
  }
  if (data && data.championship && data.championship.groups)
    return <ChampionShipGroupList groups={data.championship} />;
};

export default ChampionshipGroup;
