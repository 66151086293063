import React from "react";
import Team from "./Team";
// import defualt from "../assets/defualt.jpg";
import "./Match.css";
import { Link } from "react-router-dom";
const Match = ({ matchData }) => {
  const renderTeam = (team) => {
    if (team) {
      return (
        <div className="round-match-matches-container">
          <span className="team-name">{team.teamName}</span>
          <img className="team-image" src={"defualt"} alt="defualt" />
        </div>
      );
    } else {
      return (
        <div className="round-match-matches-container">
          <span>لايوجد فريق بعد</span>
          <img className="team-image" src={"defualt"} alt="defualt" />
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={`${
          matchData.team1 && matchData.team2
            ? `match match-${matchData.id}`
            : " match-without-lines"
        } `}
      >
        <Link to={`/edit-rounds-matches/${matchData.id}`}>
          <Team className={`${matchData.winner ? "winner" : "loser"}`}>
            {renderTeam(matchData.team1)}
          </Team>
          <div className="vs">vs</div>
          <Team>{renderTeam(matchData.team2)}</Team>
        </Link>

        {matchData.firstPrize && <div>saleh</div>}
      </div>
    </>
  );
};

export default Match;
