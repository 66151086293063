import styled from "styled-components";
import { format, isToday } from "date-fns";
import {
  HiArrowDownOnSquare,
  HiArrowUpOnSquare,
  HiEye,
  HiPencil,
  HiTrash,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import Tag from "../../ui/Tag";
import Table from "../../ui/Table";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import ConfirmDelete from "../../ui/ConfirmDelete";
// import { useDeleteConsultation } from "./useDeleteConsultation";
import useAuth from "../../hooks/useAuth";
import { deleteDeveloper } from "../../services/apiDeveloper";
import DeveloperForm from "./DeveloperForm";
import EditDeveloperForm from "./EditDeveloperForm";
import { ImagePath } from "../../utils/constants";
const Cabin = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-600);
  font-family: "Sono";
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;

const Amount = styled.div`
  font-family: "Sono";
  font-weight: 500;
`;

function DeveloperRow({ developer }) {
  const { id, image, translations, website } = developer;
  const arabicTranslation = translations[0];
  const englishTranslation = translations[1];
  const navigate = useNavigate();
  const { auth } = useAuth();
  console.log(image);
  // const { deleteConsultation, isDeleting } = useDeleteConsultation();
  console.log();
  const statusToTagName = {
    unconfirmed: "silver",
    pending: "blue",
    confirmed: "green",
  };
  return (
    <Table.Row>
      <Cabin>{id}</Cabin>

      <Stacked>
        <img src={`${ImagePath}/${image.path}`} />
      </Stacked>
      <Stacked>
        <span>{arabicTranslation.name}</span>
      </Stacked>
      <Stacked>
        <span>{englishTranslation.name}</span>
      </Stacked>
      <Stacked>
        <a href={website} target="blank">
          {website}
        </a>
      </Stacked>

      {/* <Tag type={statusToTagName[status]}>{status.replace("-", " ")}</Tag> */}

      <Modal>
        <Menus.Menu>
          <Menus.Toggle id={id} />
          <Menus.List id={id}>
            <Menus.Button
              icon={<HiEye />}
              onClick={() => navigate(`/developer/${id}`)}
            >
              See details
            </Menus.Button>
            {/* 
            {status === "unconfirmed" && (
              <Menus.Button
                icon={<HiArrowDownOnSquare />}
                onClick={() => navigate(`/checkin/${id}`)}
              >
                Check in
              </Menus.Button>
            )} */}
            <Modal.Open opens="edit">
              <Menus.Button icon={<HiPencil />}>Edit</Menus.Button>
            </Modal.Open>

            <Modal.Open opens="delete">
              <Menus.Button icon={<HiTrash />}>Delete Developer</Menus.Button>
            </Modal.Open>
          </Menus.List>
          <Modal.Window name="edit">
            <EditDeveloperForm id={id} />
          </Modal.Window>
        </Menus.Menu>

        <Modal.Window name="delete">
          <ConfirmDelete
            resourceName="Developer"
            // disabled={isDeleting}
            onConfirm={() => deleteDeveloper({ token: auth.token }, id)}
          />
        </Modal.Window>
      </Modal>
    </Table.Row>
  );
}

export default DeveloperRow;
