import { useEffect, useState } from "react";
import useFetchData from "../../services/useFetchData";
import { baseURL } from "../../api/API_Base_URL";
import usePost from "../../services/usePost";
import Spinner from "../../ui/Spinner";
import Table from "../../ui/Table";
import ChampionshipRow from "./ChampionshipRow";
import { useParams } from "react-router-dom";
import ChampionshipReqestsRow from "./ChampionshipReqestsRow";
import Row from "../../ui/Row";

const ChampionshipRequests = () => {
  const [requests, setRequests] = useState([]);
  const { championshipId } = useParams();
  const { data, isLoading, error } = useFetchData(
    `${baseURL}/getAllChampionshipRequests/${championshipId}`
  );
  const { postData } = usePost();
  useEffect(() => {
    if (data && data.championshipRequests) {
      setRequests(data.championshipRequests);
    }
  }, [data]);
  const handleApprove = async (requestId) => {
    await postData(`${baseURL}/addTeamsToChampionship/${requestId}`);
    setRequests((current) => current.filter((req) => req.id !== requestId));
  };
  const handleReject = async (requestId) => {
    await postData(`${baseURL}/rejectChampionshipRequest/${requestId}`);
    setRequests((current) => current.filter((req) => req.id !== requestId));
  };

  console.log(requests);
  if (isLoading) return <Spinner />;
  if (data && data.championshipRequests)
    return (
      <>
        <Row>
          <div>Number of Teams in This Championship : {data?.teamsCount}</div>
        </Row>
        <Table columns="2.3fr 1.6fr 1.6fr 1.6fr 1fr 1fr ">
          <Table.Header>
            <div>Team Name</div>
            <div>Coach Name </div>
            <div>Coach Number </div>
            <div>Iban </div>
            <div>Approve </div>
            <div>Reject </div>
          </Table.Header>

          <Table.Body
            data={data.championshipRequests}
            render={(Championship) => (
              <ChampionshipReqestsRow
                handleReject={handleReject}
                handleApprove={handleApprove}
                key={Championship.id}
                teamsCount={data?.teamsCount}
                Championship={Championship}
              />
            )}
          />

          <Table.Footer>
            {/* <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              onPageChange={handlePageChange}
            /> */}
          </Table.Footer>
        </Table>
      </>
    );
};

export default ChampionshipRequests;
