import { useState, useCallback } from "react";

const usePost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const postData = useCallback(
    async (url, data = {}, useFormData = false, config = {}) => {
      setIsLoading(true);
      setError(null);
      setResponse(null);
      console.log("hi");
      try {
        let body;
        let headers = {};

        // Check if the data should be wrapped in FormData
        if (useFormData) {
          const formData = new FormData();
          for (const key in data) {
            formData.append(key, data[key]);
          }
          body = formData;
        } else {
          const isFormData = data instanceof FormData;
          body = isFormData ? data : JSON.stringify(data);
          headers = isFormData
            ? {}
            : { "Content-Type": "application/json", ...config.headers };
        }
        console.log(body);
        const response = await fetch(url, {
          method: "POST",
          headers,
          body,
          ...config,
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Something went wrong!");
        }
        console.log("befor");

        const responseData = await response.json();
        setResponse(responseData);
        console.log(responseData);
        // console.log("تم")
      } catch (err) {
        setError(err.message || "Failed to post data");
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { postData, isLoading, error, response };
};

export default usePost;
