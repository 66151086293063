import styled from "styled-components";
import { format, isToday } from "date-fns";
import {
  HiArrowDownOnSquare,
  HiArrowUpOnSquare,
  HiEye,
  HiPencil,
  HiTrash,
} from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";

import Tag from "../../ui/Tag";
import Table from "../../ui/Table";
import Modal from "../../ui/Modal";
import Menus from "../../ui/Menus";
import ConfirmDelete from "../../ui/ConfirmDelete";
// import { useDeleteConsultation } from "./useDeleteConsultation";
import useAuth from "../../hooks/useAuth";
import { deleteChampionship } from "../../services/apiChampionship";
import { useEffect, useState } from "react";
const Cabin = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--color-grey-600);
  font-family: "Sono";
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
  &.team1 {
    border-right: 2px solid var(--color-grey-500);
  }
  &.team2 {
    border-right: 2px solid var(--color-grey-500);
  }
`;

const Amount = styled.div`
  font-family: "Sono";
  font-weight: 500;
`;

const StackedImage = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & span:first-child {
    font-weight: 500;
  }

  & span:last-child {
    color: var(--color-grey-500);
    font-size: 1.2rem;
  }
`;
const ImageContainer = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-size: cover;
  background-image: cover;
  object-fit: cover;
`;

function HTHReqRow({ Championship, handleReject, handleApprove, teamsCount }) {
  const { id, ibanNumber1, ibanNumber2, team1, team2, h2_h_match } =
    Championship;
  const team1Name = team1.teamName;
  const team1CoachName = team1.coachName;
  const team1CoachNumber = team1.coachPhoneNumber;
  const team2Name = team2.teamName;
  const team2CoachName = team2.coachName;
  const team2CoachNumber = team2.coachPhoneNumber;
  const time = h2_h_match?.time;
  const date = h2_h_match?.date;
  const [ibanNumberNew, setIbanNumberNew] = useState("");
  const [disableApprove, setDisableApprove] = useState(false);
  useEffect(() => {
    if (ibanNumber1 === "AE090975443675520000001") {
      setIbanNumberNew("Ghaith Iban");
    } else if (ibanNumber1 === "AE640500000000019162142") {
      setIbanNumberNew("Anas Iban");
    } else {
      setIbanNumberNew("كاش");
    }
  }, [
    // teamsCount,
    ibanNumber1,
  ]);

  // const { deleteConsultation, isDeleting } = useDeleteConsultation();
  console.log();
  const statusToTagName = {
    "Group Stage": "silver",
    open: "blue",
    ended: "green",
  };
  return (
    <Table.Row>
      <Stacked>{team1Name}</Stacked>
      <Stacked>{team1CoachName}</Stacked>
      <Stacked>{team1CoachNumber}</Stacked>
      <Stacked className="team1">{ibanNumber1}</Stacked>
      <Stacked>{team2Name}</Stacked>
      <Stacked>{team2CoachName}</Stacked>
      <Stacked>{team2CoachNumber}</Stacked>
      <Stacked className="team2">{ibanNumber2}</Stacked>
      <Stacked>{date}</Stacked>
      <Stacked>{time}</Stacked>

      <Stacked>
        <Approve
          disabled={disableApprove}
          className={`${
            disableApprove ? "approve-btn-disabled" : ""
          } approve-btn`}
          onClick={() => handleApprove(id)}
        >
          Approve
        </Approve>
      </Stacked>
      <Stacked>
        <RejectButton className="reject-btn" onClick={() => handleReject(id)}>
          Reject
        </RejectButton>
      </Stacked>
    </Table.Row>
  );
}

export default HTHReqRow;
const RejectButton = styled.div`
  color: white;
  background-color: #b91c1c;
  font-weight: 500;
  border-radius: 9999px;
  font-size: 0.875rem;
  padding: 0.425rem 1rem;
  text-align: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #991b1b;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.25rem #fca5a5;
  }
`;

const Approve = styled.div`
  color: white;
  background-color: #0ca853;
  font-weight: 500;
  border-radius: 9999px;
  font-size: 0.875rem;
  padding: 0.425rem 1rem;
  text-align: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #07914c;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.25rem #a5fcb2;
  }

  .approve-btn-disabled:hover {
    cursor: none;
    background-color: #444;
  }

  .approve-btn-disabled {
    cursor: none;
    background-color: #444;
  }

  .approve-btn-focus {
    cursor: none;
    box-shadow: none;
  }
`;
